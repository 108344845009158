import JQuery from 'jquery';
let $ = JQuery;

//const url = new URL("http://192.168.1.198/demirag-api/admin/")
//const url = new URL("http://89.252.181.6/vela/vela-api/admin/")
const url = new URL("https://flowpro.demiragosb.org.tr/demirag-api/admin/")

var WSProvider = {
    apiPath: url,
    path: url.origin + '/demirag/',

    documentPath: url + '../../demirag-api/admin/belge/upload/',
    imagePath: url + "../../demirag-api/admin/belge/upload/", // Resimler bazı projelerde buyuk - orta ve kucuk olarak ölceklendiriliyor. Küçük projelerde tek boyut olabiliyor.
    imagePathBuyuk: url + "../../demirag-api/admin/belge/upload/buyuk/",
    imagePathOrta: url + "../../demirag-api/admin/belge/upload/orta/",
    imagePathKucuk: url + "../../demirag-api/admin/belge/upload/kucuk/",

    ajaxPost: function(serviceName, jsonParams) {
        var form_data = new FormData(); // Gelen değerleri form data objesine cevirme. Daha cok array gönderebilmek için yazıldı! Önceden arrayler stringe cevrilip gönderiliyormuş :))
        for (var key in jsonParams) {
            if (Array.isArray(jsonParams[key])) { // Gelen deger array mi?
                if (jsonParams[key].length > 0) { // Gelen array içindeki de array mi?
                    for (var i = 0; i < jsonParams[key].length; i++) {
                        form_data.append(key + "[]", jsonParams[key][i]); // Formdata'ya array olan elemanları ekleme
                    }
                } else {
                    form_data.append(key + "[]", "");
                }
            } else form_data.append(key, (jsonParams[key]));
        }
        var service = new Promise(function(resolve, reject) {
            return $.ajax({
                type: 'POST',
                url: url + serviceName + ".php",
                datatype: 'json',
                processData: false, // Important!
                contentType: false,
                data: form_data,
                success: function(result) {
                    if (result) {
                        resolve(result)
                    } else {
                        resolve()
                    }
                },
                error: function(request) {
                    if (request) {
                        reject(request)
                    } else {
                        reject()
                    }
                }
            })
        })
        return service
    },
}
export default WSProvider