const Bulten = resolve => {
    require.ensure(['../views/apps/bulten/Bulten'], () => {
        resolve(require('../views/apps/bulten/Bulten'))
    })
};
const BultenTanimla = resolve => {
    require.ensure(['../views/apps/bulten/BultenTanimla'], () => {
        resolve(require('../views/apps/bulten/BultenTanimla'))
    })
};
const BultenListe = resolve => {
    require.ensure(['../views/apps/bulten/BultenListe'], () => {
        resolve(require('../views/apps/bulten/BultenListe'))
    })
};
const BultenDuzenle = resolve => {
    require.ensure(['../views/apps/bulten/BultenDuzenle'], () => {
        resolve(require('../views/apps/bulten/BultenDuzenle'))
    })
};

const BultenKategoriListe = resolve => {
    require.ensure(['../views/apps/bulten/kategori/BultenKategoriTanimlama'], () => {
        resolve(require('../views/apps/bulten/kategori/BultenKategoriTanimlama'))
    })
};

import Vue from 'vue'
import layouts from '../layout'

Vue.component('bultentanimla', BultenTanimla);
Vue.component('bultenlistesi', BultenListe);
Vue.component('bultenduzenle', BultenDuzenle);
Vue.component('bultenkategori-listesi', BultenKategoriListe);


export default {
    path: '/bulten',
    name: 'bulten',
    component: Bulten,
    meta: {
        layout: layouts.navLeft
    },
}
