const Belge = resolve => {
    require.ensure(['../views/apps/belge/Belge'], () => {
        resolve(require('../views/apps/belge/Belge'))
    })
};
const BelgeListe = resolve => {
    require.ensure(['../views/apps/belge/BelgeListe'], () => {
        resolve(require('../views/apps/belge/BelgeListe'))
    })
};


import Vue from 'vue'

Vue.component('belgeler', BelgeListe);

import layouts from '../layout'

export default {
    path: '/belgeler',
    name: 'belgeler',
    component: Belge,
    meta: {
        auth: true,
        layout: layouts.navLeft
    },
}