const Rakam = resolve => {
    require.ensure(['../views/apps/rakam/Rakam'], () => {
        resolve(require('../views/apps/rakam/Rakam'))
    })
};
const RakamTanimla = resolve => {
    require.ensure(['../views/apps/rakam/RakamTanimla'], () => {
        resolve(require('../views/apps/rakam/RakamTanimla'))
    })
};


import Vue from 'vue'
Vue.component('rakamtanimla', RakamTanimla);

import layouts from '../layout'

export default {
    path: '/rakam',
    name: 'rakam',
    component: Rakam,
    meta: {
        layout: layouts.navLeft
    },
}