const Menu = resolve => {
    require.ensure(['../views/apps/menu/Menu'], () => {
        resolve(require('../views/apps/menu/Menu'))
    })
};
const MenuListe = resolve => {
    require.ensure(['../views/apps/menu/MenuListe'], () => {
        resolve(require('../views/apps/menu/MenuListe'))
    })
};

import Vue from 'vue'
import layouts from '../layout'
Vue.component('menulistesi', MenuListe);

export default {
    path: '/menu',
    name: 'menu',
    component: Menu,
    meta: {
        auth: true,
        layout: layouts.navLeft
    },
}