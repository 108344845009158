const Firma = resolve => {
    require.ensure(['../views/apps/firma/Firma'], () => {
        resolve(require('../views/apps/firma/Firma'))
    })
};
const FirmaTanimla = resolve => {
    require.ensure(['../views/apps/firma/FirmaTanimla'], () => {
        resolve(require('../views/apps/firma/FirmaTanimla'))
    })
};

const FirmaDetay = resolve => {
    require.ensure(['../views/apps/firma/FirmaDetay'], () => {
        resolve(require('../views/apps/firma/FirmaDetay'))
    })
};
const YoneticiTanimla = resolve => {
    require.ensure(['../views/apps/firma/YoneticiTanimla'], () => {
        resolve(require('../views/apps/firma/YoneticiTanimla'))
    })
};
const YoneticiListesi = resolve => {
    require.ensure(['../views/apps/firma/YoneticiListesi'], () => {
        resolve(require('../views/apps/firma/YoneticiListesi'))
    })
};
const YoneticiDuzenle = resolve => {
    require.ensure(['../views/apps/firma/YoneticiDuzenle'], () => {
        resolve(require('../views/apps/firma/YoneticiDuzenle'))
    })
};

import Vue from 'vue'
import layouts from '../layout'
Vue.component('firmakayit', FirmaTanimla);
Vue.component('firmadetay', FirmaDetay);
Vue.component('yoneticitanimla', YoneticiTanimla);
Vue.component('yoneticiguncelle', YoneticiDuzenle);
Vue.component('yoneticilistesi', YoneticiListesi);

export default {
    path: '/firma',
    name: 'firma',
    component: Firma,
    meta: {
        layout: layouts.navLeft
    }
}