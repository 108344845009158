<template>
	<div  class="horizontal-nav flex" :class="{['pos-'+position]:true}">
		<logo mode="horizontal"/>
		<div class="box grow flex nav-container">
			<vue-scroll class="box grow  animated fadeInDown" :key="refreshKey">
				<Nav :open-sidebar.sync="openSidebar" @push-page="pushPage" mode="horizontal"/>
			</vue-scroll>
		</div>
		<div class="hidden-md-and-down header">
			<Toolbar mode="horizontal/" @toggle-sidebar="openSidebar = !openSidebar" :menu-burger="navPos"/>
		</div>
	</div>
</template>


<script>
import Nav from '@/core/nav.vue'
import Logo from '@/core/logo.vue'
import Toolbar from '@/core/toolbar.vue'


export default {
	name: 'HorizontalNav',
	props: ['position'],
	data() {
		return {
			openSidebar: false,
		}
	},
	methods: {
		pushPage(index, indexPath) {
			this.$emit('push-page', {page:index})
		},
		closeSidebar() {
			this.openSidebar = false
		}
	},
	computed: {
		navPos() {
			if (this.innerWidth <= 768 && (this.$store.getters.navPos === 'top' || this.$store.getters.navPos === 'bottom')) {
				return 'left'
			}
			return this.$store.getters.navPos
		},
		toolbar() {
			return this.$store.getters.toolbar
		},
		refreshKey(){
			return this.$store.getters.getRefreshKey
		}
	},
	components: {
		Nav,
		Logo,
		Toolbar
	}
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/_variables';

.horizontal-nav {
	overflow: hidden;
	/*overflow-x: scroll;*/
	/*overflow-y: hidden;*/
	/*-webkit-overflow-scrolling: touch;*/
	/*-ms-overflow-style: -ms-autohiding-scrollbar;*/
	background: #e3eaf0;
	height: 70px;
	z-index: 1;
	/*display: table;*/
	/*margin-bottom: 30px;*/

	&.pos-top {
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
	}
	&.pos-bottom {
		border-top-left-radius: 16px;
		border-top-right-radius: 16px;
	}

	.nav-container {
		position: relative;
		overflow: hidden;
		padding-right: 20px;

		&::before {
			content: '';
			display: none;
			z-index: 1;
			position: absolute;
			top: 0px;
			left: 0px;
			width: 0px;
			height: 20px;
			box-shadow: 0px 0px 15px 15px #fff;
		}
		&::after {
			content: '';
			display: none;
			z-index: 1;
			position: absolute;
			top: 0px;
			right: 20px;
			width: 0px;
			height: 20px;
			box-shadow: 0px 0px 15px 15px #fff;
		}
	}
}
</style>
