import WsProvider from './provider/WSProvider'

var serviceName = "webservis";
var moduleName = "firma";


var analitikService = {
    analitikList() {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            serviceName: moduleName,
            methodName: "analitikListe"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
}


export default analitikService