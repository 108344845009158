const Cari = resolve => {
    require.ensure(['../views/apps/cari/Cari'], () => {
        resolve(require('../views/apps/cari/Cari'))
    })
};
const CariTanimla = resolve => {
    require.ensure(['../views/apps/cari/CariTanimla'], () => { 
        resolve(require('../views/apps/cari/CariTanimla'))
    })
};
const CariListe = resolve => {
    require.ensure(['../views/apps/cari/CariListe'], () => {
        resolve(require('../views/apps/cari/CariListe'))
    })
};
const CariGuncelle = resolve => {
    require.ensure(['../views/apps/cari/CariGuncelle'], () => {
        resolve(require('../views/apps/cari/CariGuncelle'))
    })
};

import Vue from 'vue'
import layouts from '../layout'
Vue.component('caritanimla', CariTanimla);
Vue.component('carilistesi', CariListe);
Vue.component('cariguncelle', CariGuncelle);

export default {
    path: '/cari',
    name: 'cari',
    component: Cari,
    meta: {
        auth: true,
        layout: layouts.navLeft
    },
}