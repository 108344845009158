const Urun = resolve => {
    require.ensure(['../views/apps/urun/Urun'], () => {
        resolve(require('../views/apps/urun/Urun'))
    })
};
const UrunTanimla = resolve => {
    require.ensure(['../views/apps/urun/UrunTanimla'], () => { 
        resolve(require('../views/apps/urun/UrunTanimla'))
    })
};
const UrunListe = resolve => {
    require.ensure(['../views/apps/urun/UrunListe'], () => {
        resolve(require('../views/apps/urun/UrunListe'))
    })
};
const UrunDuzenle = resolve => {
    require.ensure(['../views/apps/urun/UrunDuzenle'], () => {
        resolve(require('../views/apps/urun/UrunDuzenle'))
    })
};

import Vue from 'vue'
import layouts from '../layout'
Vue.component('uruntanimla', UrunTanimla);
Vue.component('urunlistesi', UrunListe);
Vue.component('urunguncelle', UrunDuzenle);

export default {
    path: '/urun',
    name: 'urun',
    component: Urun,
    meta: {
        auth: true,
        layout: layouts.navLeft
    },
}