const Evrak = resolve => {
    require.ensure(['../views/apps/evrak/Evrak'], () => {
        resolve(require('../views/apps/evrak/Evrak'))
    })
};
const EvrakTanimla = resolve => {
    require.ensure(['../views/apps/evrak/EvrakTanimla'], () => { 
        resolve(require('../views/apps/evrak/EvrakTanimla'))
    })
};
const EvrakListe = resolve => {
    require.ensure(['../views/apps/evrak/EvrakListe'], () => {
        resolve(require('../views/apps/evrak/EvrakListe'))
    })
};
const EvrakGuncelle = resolve => {
    require.ensure(['../views/apps/evrak/EvrakGuncelle'], () => {
        resolve(require('../views/apps/evrak/EvrakGuncelle'))
    })
};

import Vue from 'vue'
import layouts from '../layout'
Vue.component('evraktanimla', EvrakTanimla);
Vue.component('evraklistesi', EvrakListe);
Vue.component('evrakguncelle', EvrakGuncelle);

export default {
    path: '/evrak',
    name: 'evrak',
    component: Evrak,
    meta: {
        auth: true,
        layout: layouts.navLeft
    },
}