import WsProvider from './provider/WSProvider'
import axios from "axios"

var serviceName = "webservis";
var moduleName = "yonetim";

var yoneticiService = {
    imagePath: WsProvider.imagePath,
    imagePathBuyuk: WsProvider.imagePathBuyuk,
    imagePathOrta: WsProvider.imagePathOrta,
    imagePathKucuk: WsProvider.imagePathKucuk,
    belgePath: WsProvider.documentPath,
    path: WsProvider.path,

    girisYap(kullaniciAdi, sifre) {
        var param = {
            user: kullaniciAdi,
            pwd: sifre,
            methodName: "logIn",
            serviceName: moduleName

        };
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },

    yoneticiTanimlama(yonetimKodu, yetkiTuru, TC, resim, yoneticiAdi, sifre, adSoyad, gorev, eposta, cepTelefonu, sabitTelefon, dahiliTelefon) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            yonetimKodu: yonetimKodu,
            yetkiTuru: yetkiTuru,
            TC: TC,
            adSoyad: adSoyad,
            gorev: gorev,
            eposta: eposta,
            resim: resim,
            cepTelefonu: cepTelefonu,
            sabitTelefon: sabitTelefon,
            dahiliTelefon: dahiliTelefon,
            yoneticiAdi: yoneticiAdi,
            sifre: sifre,
            durum: 1,
            methodName: "yoneticiEkle",
            serviceName: moduleName
        }

        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },

    yoneticiGuncelle(yoneticiID, resim, adSoyad, eposta, cepTelefonu, sabitTelefon, dahiliTelefon, rol, yoneticiAdi) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            yoneticiID: yoneticiID,
            resim: resim,
            adSoyad: adSoyad,
            eposta,
            yoneticiAdi,
            cepTelefonu: cepTelefonu,
            sabitTelefon: sabitTelefon,
            dahiliTelefon: dahiliTelefon,
            rol,
            durum: 1,
            methodName: "yoneticiGuncelle",
            serviceName: moduleName
        }

        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },


    yoneticiListe(durum, baslangic, limit) {
        var userDataDemirag = localStorage.getItem("userDataDemirag");
        var param = {
            token: userDataDemirag,
            durum,
            baslangic,
            limit,
            methodName: "listele",
            serviceName: moduleName

        };
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },

    yoneticiCount(durum) {
        var userDataDemirag = localStorage.getItem("userDataDemirag");
        var param = {
            token: userDataDemirag,
            durum,
            methodName: "yoneticiSayisi",
            serviceName: moduleName

        };
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },


    getAccount() {
        var userDataDemirag = localStorage.getItem("userDataDemirag");
        var param = {
            token: userDataDemirag,
            methodName: "getAccount",
            serviceName: moduleName
        };
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },


    yoneticiSifreGuncelle(yoneticiAdi, yeniSifre) {
        var userDataDemirag = localStorage.getItem("userDataDemirag");
        var param = {
            token: userDataDemirag,
            yoneticiAdi: yoneticiAdi,
            yeniSifre: yeniSifre,
            methodName: "yoneticiSifreGuncelle",
            serviceName: moduleName

        };
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },

    // yoneticinin kendi şifresini güncellediği metot
    sifreGuncelle(eskiSifre, yeniSifre) {
        var userDataDemirag = localStorage.getItem("userDataDemirag");
        var param = {
            token: userDataDemirag,
            eskiSifre: eskiSifre,
            yeniSifre: yeniSifre,
            methodName: "sifreGuncelle",
            serviceName: moduleName

        };
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },

    yoneticiAktifYap(yoneticiID) {
        var userDataDemirag = localStorage.getItem("userDataDemirag");
        var param = {
            token: userDataDemirag,
            yonetimID: yoneticiID,
            durum: 1,
            methodName: "aktifPasifEt",
            serviceName: moduleName

        };
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },

    yoneticiPasifYap(yoneticiID) {
        var userDataDemirag = localStorage.getItem("userDataDemirag");
        var param = {
            token: userDataDemirag,
            yonetimID: yoneticiID,
            durum: 0,
            methodName: "aktifPasifEt",
            serviceName: moduleName
        };
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },

    sifremiUnuttum() {

    }

};
export default yoneticiService