import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate' // store değişkenlerinin otomatik olarak localstore'a kaydediyor.

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    state: {
        kisiUpdateData: "",
        kurumUpdateData: "",
        evrakUpdateData: "",
        subeUpdateData: "",
        brandUpdateData: "",
        urunUpdateData: "",
        multipleSelectionOrder: [],
        yoneticiUpdateData: "",
        sayfaData: {},
        bultenData: {},
        sliderData: {},
        popData: {},
        urunKategoriUpdateData: "",
        galeriUpdateData: {},
        refreshKey: 0,
        tabList: [],
        activeTabs: '',
        updateKodu: '',
        siparisDurum: '2',
        yetkiTuru: "", // 0 = yetkisiz, 1 = root(yonetici), 2 = personel 
        yetkiListesi: [],
        secure: [],
        navVisible: true,
        dilDurum: false,
        tabsClose: true,
        sayfaList: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],


        layout: {
            menuSecim: false, // false = web || true = otomasyon
            dashboardGorunum: ['sayfaSayisi', 'siparisSayisi', 'urunSayisi', 'musteriSayisi', 'markaSayisi', 'ziyaretciIstatistik', 'islemListesi', 'yoneticiListesi'],
            karanlikMod: true, //true = aydınlık, false = karanlik
            navPos: 'top', //top, bottom, left, right, false
            toolbar: 'top', //top, bottom, false
            footer: true, //above, below, false
            boxed: false, //true, false
            roundedCorners: false, //true, false
            viewAnimation: 'fade-top' // fade-left, fade-right, fade-top, fade-top-in-out, fade-bottom, fade-bottom-in-out, fade, false
        },
        splashScreen: true,
        logged: true,
        userName: "",
    },
    mutations: {
        changeSubeUpdateData: (state, value) => {
            state.subeUpdateData = value;
        },
        changeKisiUpdateData: (state, value) => {
            state.kisiUpdateData = value;
        },
        changeBrandUpdateData: (state, value) => {
            state.brandUpdateData = value;
        },
        changeYoneticiUpdateData: (state, value) => {
            state.yoneticiUpdateData = value;
        },
        changeKurumUpdateData: (state, value) => {
            state.kurumUpdateData = value
        },
        changeEvrakUpdateData: (state, value) => {
            state.evrakUpdateData = value
        },
        changeUrunUpdateData: (state, value) => {
            state.urunUpdateData = value;
        },
        changeMultipleSelectionOrder: (state, value) => {
            state.multipleSelectionOrder.push(value);
        },
        removeMultipleSelectionOrder: (state, value) => {
            state.multipleSelectionOrder.splice(value, 1);
        },
        clearMultipleSelectionOrder: (state, value) => {
            state.multipleSelectionOrder = [];
        },
        changeRefreshKey: (state, value) => {
            state.refreshKey += 1;
        },
        changeMenuSecim: (state, value) => {
            state.layout.menuSecim = value;
        },
        changeGaleriUpdateData: (state, value) => {
            state.galeriUpdateData = value;
        },
        changeSayfaData: (state, value) => {
            state.sayfaData = value;
        },
        changeBultenData: (state, value) => {
            state.bultenData = value;
        },
        changeSliderData: (state, value) => {
            state.sliderData = value;
        },
        changePopData: (state, value) => {
            state.popData = value;
        },
        changeUrunKategoriUpdate: (state, value) => {
            state.urunKategoriUpdateData = value;
        },
        setUserName(state, value) {
            state.userName = value
        },
        secure: (state, id) => {
            var obj = state.secure.find(data => data.id === id);
            if (obj) {
                obj.count++;
            } else {
                state.secure.push({
                    id: id,
                    count: 1,
                    sonGiris: new Date().getTime()
                })
            }
        },
        resSecure: (state, id) => {
            var obj = state.secure.find(data => data.id === id);
            state.secure.splice(state.secure.indexOf(obj), 1)
        },
        changeModulList: (state, value) => {
            state.modulList = value
        },
        changeTabList: (state, value) => {
            state.tabList = value
        },
        changeTabListAdd: (state, value) => {
            state.tabList.push(value)
        },
        changeTabListRemove: (state, value) => {
            state.tabList.splice(value, 1)
        },
        changeActiveTabs: (state, value) => {
            state.activeTabs = value
        },
        changeUpdateKodu: (state, value) => {
            state.updateKodu = value
        },
        changeNavVisible: (state, value) => {
            state.navVisible = value
        },
        changeSayfaList: (state, value) => {
            state.sayfaList = value
        },
        changeTabsClose: (state, value) => {
            state.tabsClose = value
        },
        changeGorunum: (state, value) => {
            state.layout.karanlikMod = value
        },
        changeDashboard: (state, value) => {
            state.layout.dashboardGorunum = value;
        },
        setLayout(state, payload) {
            if (payload && payload.navPos !== undefined)
                state.layout.navPos = 'top'

            if (payload && payload.toolbar !== undefined)
                state.layout.toolbar = payload.toolbar

            if (payload && payload.footer !== undefined)
                state.layout.footer = payload.footer

            if (payload && payload.boxed !== undefined)
                state.layout.boxed = payload.boxed

            if (payload && payload.roundedCorners !== undefined)
                state.layout.roundedCorners = payload.roundedCorners

            if (payload && payload.viewAnimation !== undefined)
                state.layout.viewAnimation = payload.viewAnimation
        },
        setLogin(state, payload) {
            state.logged = true
        },
        setLogout(state, payload) {
            state.layout.navPos = null
            state.layout.toolbar = null
            state.logged = false
        },
        setSplashScreen(state, payload) {
            state.splashScreen = payload
        },
        setYetkiTuru(state, yetki) {
            state.yetkiTuru = yetki
        }

    },
    actions: {},
    getters: {
        getKisiUpdateData: (state) => {
            return state.kisiUpdateData
        },
        getSubeUpdateData: (state) => {
            return state.subeUpdateData;
        },
        getBrandUpdateData: (state) => {
            return state.brandUpdateData;
        },
        getKurumUpdateData: (state) => {
            return state.kurumUpdateData;
        },
        getEvrakUpdateData: (state) => {
            return state.evrakUpdateData;
        },
        getUrunUpdateData: (state) => {
            return state.urunUpdateData;
        },
        getMultipleSelectionOrder: (state) => {
            return state.multipleSelectionOrder;
        },
        getYoneticiUpdateData(state) {
            return state.yoneticiUpdateData;
        },
        getGaleriUpdateData: (state) => {
            return state.galeriUpdateData;
        },
        getRefreshKey: (state) => {
            return state.refreshKey;
        },
        getMenuSecim: (state) => {
            return state.layout.menuSecim;
        },
        getSayfaData(state) {
            return state.sayfaData;
        },
        getBultenData(state) {
            return state.bultenData;
        },
        getSliderData(state) {
            return state.sliderData;
        },
        getPopData(state) {
            return state.popData;
        },
        getUrunKategoriData: (state) => {
            return state.urunKategoriUpdateData;
        },
        getUserName(state) {
            return state.userName
        },
        getSecure: (state) => (id) => {
            var obj = state.secure.find(data => data.id === id);
            if (obj) {
                return obj.count;
            } else return 1
        },
        getSecureSonGiris: (state) => (id) => {
            var obj = state.secure.find(data => data.id === id);
            if (obj) {
                return obj.sonGiris;
            }
        },
        getTabList(state, getters) {
            return state.tabList
        },
        getUpdateKodu(state, getters) {
            return state.updateKodu
        },
        layout(state, getters) {
            return state.layout
        },
        navPos(state, getters) {
            return state.layout.navPos
        },
        toolbar(state, getters) {
            return state.layout.toolbar
        },
        footer(state, getters) {
            return state.layout.footer
        },
        boxed(state, getters) {
            return state.layout.boxed
        },
        roundedCorners(state, getters) {
            return state.layout.roundedCorners
        },
        viewAnimation(state, getters) {
            return state.layout.viewAnimation
        },
        isLogged(state, getters) {
            return state.logged
        },
        splashScreen(state, getters) {
            return state.splashScreen
        },
        getYetkiTuru(state, getters) {
            return state.yetkiTuru
        },
    },
    plugins: [createPersistedState()],
    strict: debug
})