const SSS = resolve => {
    require.ensure(['../views/apps/sss/SSS'], () => {
        resolve(require('../views/apps/sss/SSS'))
    })
};

const SSSListe = resolve => {
    require.ensure(['../views/apps/sss/SSSListe'], () => {
        resolve(require('../views/apps/sss/SSSListe'))
    })
};

import Vue from 'vue'
Vue.component('sss', SSSListe);

import layouts from '../layout'

export default {
    path: '/sss',
    name: 'sss',
    component: SSS,
    meta: {
        layout: layouts.navLeft
    },
    children: [{
        path: 'sss',
        name: 'sss',
        component: SSSListe,
        meta: {
            layout: layouts.navLeft,
            searchable: true,
            tags: ['app']
        }
    }, ]
}