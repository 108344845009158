const Slider = resolve => {
    require.ensure(['../views/apps/slider/Slider'], () => {
        resolve(require('../views/apps/slider/Slider'))
    })
};
const SliderTanimla = resolve => {
    require.ensure(['../views/apps/slider/SliderTanimla'], () => {
        resolve(require('../views/apps/slider/SliderTanimla'))
    })
};

const SliderListe = resolve => {
    require.ensure(['../views/apps/slider/SliderListe'], () => {
        resolve(require('../views/apps/slider/SliderListe'))
    })
};

const SliderDuzenle = resolve => {
    require.ensure(['../views/apps/slider/SliderDuzenle'], () => {
        resolve(require('../views/apps/slider/SliderDuzenle'))
    })
};
import Vue from 'vue'
import layouts from '../layout'

Vue.component('slidertanimla', SliderTanimla);
Vue.component('sliderlistesi', SliderListe);
Vue.component('sliderguncelle', SliderDuzenle);

export default {
    path: '/slider',
    name: 'slider',
    component: Slider,
    meta: {
        auth: true,
        layout: layouts.navLeft
    },
}