const Sayfa = resolve => {
    require.ensure(['../views/apps/sayfa/Sayfa'], () => {
        resolve(require('../views/apps/sayfa/Sayfa'))
    })
};
const SayfaTanimla = resolve => {
    require.ensure(['../views/apps/sayfa/SayfaTanimla'], () => {
        resolve(require('../views/apps/sayfa/SayfaTanimla'))
    })
};
const SayfaListesi = resolve => {
    require.ensure(['../views/apps/sayfa/SayfaListesi'], () => {
        resolve(require('../views/apps/sayfa/SayfaListesi'))
    })
};
const SayfaGuncelle = resolve => {
    require.ensure(['../views/apps/sayfa/SayfaGuncelle'], () => {
        resolve(require('../views/apps/sayfa/SayfaGuncelle'))
    })
};

import Vue from 'vue'
import layouts from '../layout'
Vue.component('sayfatanimla', SayfaTanimla);
Vue.component('sayfalistesi', SayfaListesi);
Vue.component('sayfaguncelle', SayfaGuncelle);

export default {
    path: '/sayfa',
    name: 'sayfa',
    component: Sayfa,
    meta: {
        auth: true,
        layout: layouts.navLeft
    },
}