const Galeri = resolve => {
    require.ensure(['../views/apps/galeri/Galeri'], () => {
        resolve(require('../views/apps/galeri/Galeri'))
    })
};
const GaleriTanimla = resolve => {
    require.ensure(['../views/apps/galeri/GaleriTanimla'], () => {
        resolve(require('../views/apps/galeri/GaleriTanimla'))
    })
};
const GaleriListe = resolve => {
    require.ensure(['../views/apps/galeri/GaleriListe'], () => {
        resolve(require('../views/apps/galeri/GaleriListe'))
    })
};

const GaleriGuncelle = resolve => {
    require.ensure(['../views/apps/galeri/GaleriGuncelle'], () => {
        resolve(require('../views/apps/galeri/GaleriGuncelle'))
    })
};

import Vue from "vue"
import layouts from '../layout'
Vue.component('galeri', Galeri)
Vue.component('galeritanimla', GaleriTanimla)
Vue.component('galerilistesi', GaleriListe)
Vue.component('galeriguncelle', GaleriGuncelle)

export default {
    path: '/galeri',
    name: 'galeri',
    component: Galeri,
    meta: {
        layout: layouts.navLeft
    },
}