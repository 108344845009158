import functions from "../functions"
import notification from "../notification"

export default {
    computed: {

    },
    methods: {
        // İşlemi onaylıyor musunuz diye acılan pencere burdan acılıyor.
        async openConfirmDialog(msg) {
            return this.$confirm(msg, this.$t("src.views.apps.genel.uyari"), {
                confirmButtonText: this.$t("src.views.apps.genel.yes"),
                cancelButtonText: this.$t("src.views.apps.genel.no"),
                type: 'warning'
            })
        },


        sayfaKapat(sayfa) {
            let tabs = this.$store.getters.getTabList;
            var obj = tabs.find(data => data.name === sayfa);
            if (obj !== undefined) {
                tabs.forEach((tab, index) => {
                    if (tab.name === sayfa) {
                        this.$store.commit('changeTabListRemove', index);
                        if (tabs[index]) {
                            this.$store.commit('changeActiveTabs', tabs[index].name);
                        } else if (tabs[index - 1]) {
                            this.$store.commit('changeActiveTabs', tabs[index - 1].name);
                        }
                    }
                });
            }
            this.$store.commit('changeTabsClose', true)
        },

        routeSayfa(name) {
            let tabs = this.$store.getters.getTabList;
            var header = functions.linkTemizle(name.replace(" ", ""));
            var obj = tabs.find(data => data.name === header);
            if (obj === undefined) {
                var item = ({
                    title: name,
                    name: header,
                    contentName: header
                });
                this.$store.commit('changeTabListAdd', item);
                this.$store.commit('changeActiveTabs', header);
            } else {
                this.$store.commit('changeActiveTabs', header);
            }
        },

        // ELEMENT UI FORMLARINI KONTROL ETMEK İÇİN VAR. EĞER İNPUTLAR KURALLARA TAMAMEN UYUYORSA TRUE DONER UYMUYORSA VEYA REFS DE HATA VARSA FALSE DÖNER.
        validateForm(formName) {
            var result = false;
            this.$refs[formName].validate((valid, errors) => {
                if (valid) {
                    result = true;
                    return true
                } else {
                    for (var k in errors) {
                        errors[k].forEach((element) => {
                            notification.Status("warning", this, element.message);
                        })
                    }
                    result = false;
                    return false;
                }
            })
            return result
        },

    }
}