<template>
    <div class="toolbar flex align-center justify-space-between">
        <div class="box-left box grow flex">
            <button @click="toggleSidebar" v-if="menuBurger !== 'right'"
                    class="toggle-sidebar card-base card-shadow--small">
                <i class="mdi mdi-menu"></i>
            </button>
        </div>
        <div class="box-right flex align-center pl-10">
            
            <!-- <el-tooltip :open-delay="300" class="item" effect="dark" content="İnternet Sitesi / Yazılım" placement="bottom">
                <vue-toggles checked-text="Yazılım"
                unchecked-text="Site"
                checked-bg="#fdc30a"
                unchecked-bg="#2c3437"
                width="105"
                :value="menuSecim"
                @click="menuSecim = !menuSecim"/>
            </el-tooltip> -->

            <!--
            <el-tooltip :open-delay="300" class="item ml-10" effect="dark" content="View Settings" placement="bottom">
                <button class="ayarlarButon" @click="ayarDrawer = true">
                    <i class="mdi mdi-settings"></i>
                </button>
            </el-tooltip>
            -->

            <el-tooltip :open-delay="300" class="item" effect="dark" content="Tam Ekran Modu" placement="bottom">
                <button class="fullscreen-button" @click="toggleFullscreen">
                    <i class="mdi mdi-fullscreen" v-if="!fullscreen"></i>
                    <i class="mdi mdi-fullscreen-exit" v-if="fullscreen"></i>
                </button>
            </el-tooltip>
            <!-- <el-tooltip :open-delay="300" class="item" effect="dark" content="Aydınlık/Karanlık Mod" placement="bottom">
                <div class="toggle toggle--daynight">
                    <input v-on:change="goruntuDegistir" type="checkbox" id="toggle--daynight" class="toggle--checkbox">
                    <label class="toggle--btn" for="toggle--daynight"><span class="toggle--feature"></span></label>
                </div>
            </el-tooltip> -->

            <span class="username effect-shine" style="cursor: pointer" v-on:click="onCommand('profile')">{{userdata.adSoyad}}</span>
            <el-dropdown trigger="click" @command="onCommand">
				<span class="el-dropdown-link">
                    <img v-if="userdata.image" :src="imageUrl+userdata.image" class="avatar" alt="avatar">
                    <img  src="@/assets/images/avatar.jpg" class="nav-avatar" alt="avatar">
				</span>
                <el-dropdown-menu slot="dropdown" trigger="click" @command="onCommand">
                    <el-dropdown-item command="profile"><i class="mdi mdi-account mr-10"></i> Profil
                    </el-dropdown-item>
                    <el-dropdown-item v-on:click="drawer=true" command="sifre"><i class="mdi mdi-key mr-10"></i> Şifre Güncelle</el-dropdown-item>
                    <el-dropdown-item command="logout" divided><i class="mdi mdi-logout mr-10"></i> Çıkış
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <el-drawer
                    size="35%"
                    title="Admin Information"
                    :visible.sync="drawer"
                    direction="rtl"
                    :append-to-body="true"
                    custom-class="demo-drawer"
                    ref="drawer">
                <div class="demo-drawer__content">
                    <el-form status-icon :model="form" :rules="rulesForm" ref="form"
                             class="demo-ruleForm formPadding">
                        <el-form-item label='Old Password' prop="eskisifre">:
                            <el-col :span="24">
                                <el-input size="small"  type="password" v-model="form.eskisifre"></el-input>
                            </el-col>
                        </el-form-item>
                        <el-form-item label='New Password' prop="sifre">:
                            <el-col :span="24">
                                <el-input size="small"  type="password" v-model="form.sifre"></el-input>
                                <password v-model="form.sifre" :strength-meter-only="true"/>
                            </el-col>
                        </el-form-item>
                        <el-form-item label='New Password (Again)' prop="sifre2">:
                            <el-col :span="24">
                                <el-input size="small"  type="password" v-model="form.sifre2"></el-input>
                            </el-col>
                        </el-form-item>
                        <el-button
                                type="primary"
                                size="mini"
                                v-on:click="editPass('form')"> Update
                        </el-button>
                    </el-form>
                </div>
            </el-drawer>
            <el-drawer
                    size="35%"
                    title="View Settings"
                    :visible.sync="ayarDrawer"
                    direction="rtl"
                    :append-to-body="true"
                    custom-class="demo-drawer"
                    ref="ayarDrawer">
                <div class="demo-drawer__content">
                    <el-row class="formPadding" style="margin-top: 0">
                        <el-col :lg="24" :md="24" :sm="24" :xs="24">
                            <el-divider class="dividerAyar" content-position="left">Widgets to Display on Dashboard</el-divider>

                            <div class="toolbar-scroll">
                                <el-checkbox-group v-model="dashboardWidget">
                                    <el-row :gutter="10">
                                        <el-col v-for="(item,index) in dashboardWidgetListe" :key="index" :lg="12" :md="12" :sm="24" :xs="24" class="margin-bottom-10">
                                            <div class="text-center">
                                                <i :class="'mdi-24px mdi mdi-'+dashboardWidgetIconlar[index]" style="line-height: 70px"></i>
                                            </div>
                                            <el-checkbox border :label="item" :key="item" style="width: 100%">{{dashboardWidgetIsimler[index]}}</el-checkbox>
                                        </el-col>
                                    </el-row>
                                </el-checkbox-group>
                            </div>
                        </el-col>
                        <el-col :lg="24" :md="24" :sm="24" :xs="24">
                            <el-button size="mini" type="primary" @click="dashboardKaydet">
                                Kaydet
                            </el-button>
                        </el-col>
                    </el-row>
                </div>
            </el-drawer>

            <button @click="toggleSidebar" v-if="menuBurger === 'right'"
                    class="toggle-sidebar toggle-sidebar__right card-base card-shadow--small">
                <i class="mdi mdi-menu"></i>
            </button>
        </div>
    </div>
</template>

<script>
    import VueToggles from "vue-toggles"

    import yonetimService from '../WSProvider/YoneticiService'
    import Password from 'vue-password-strength-meter'
    import notification from '../notification'
    import functions from '../functions'
    import JQuery from 'jquery'
    import EventBus from '@/components/event-bus'
    let $ = JQuery
    var base64 = require('base64_js');

    import jwt_decode from "jwt-decode";

    export default {
        name: 'Toolbar',
        props: ['menuBurger'],
        components: {
            Password,
            VueToggles,
        },
        watch: {
          menuSecim(val){
            this.$store.commit("changeMenuSecim", val)
          }  
        },
        data() {
            var validatePass = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('Please Enter Password'));
                } else {
                    if (this.form.sifre2 !== '') {
                        this.$refs.form.validateField('sifre2');
                    }
                    callback();
                }
            };
            var validatePass2 = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('Please Password Again'));
                } else if (value !== this.form.sifre) {
                    callback(new Error('Pasword does not match!!'));
                } else {
                    callback();
                }
            };
            return {
                menuSecim: false,

                dashboardWidget: this.$store.state.layout.dashboardGorunum,
                dashboardWidgetListe: ['sayfaSayisi', 'siparisSayisi', 'urunSayisi', 'musteriSayisi', 'markaSayisi', 'ziyaretciIstatistik', 'islemListesi', 'yoneticiListesi'],
                dashboardWidgetIconlar: ['newspaper', 'newspaper', 'newspaper', 'newspaper', 'newspaper', 'newspaper', 'newspaper', 'newspaper', 'newspaper'],
                dashboardWidgetIsimler: ['Number of Pages', 'Number of Orders', 'Number of Products', 'Number of Customers', 'Number of Brands ', 'Statictics', 'Transaction List', 'Administrator List'],
                tabList : [],
                imageUrl: yonetimService.imageUrlKucuk,
                drawer: false,
                ayarDrawer: false,
                sifreTekrarVis: false,
                dialog: false,
                popoverWidth: 300,
                fullscreen: false,
                userdata: {
                    adSoyad : "",
                    image: "",
                },
                updateData: [],
                yoneticiDetayListesi: [],
                visibleSifre: false,
                form: {
                    eskisifre: '',
                    sifre: '',
                    sifre2: ''
                },
                rulesForm: {
                    eskisifre: [{
                        required: true,
                        message: " ",
                        trigger: 'blur'
                    }],
                    sifre: [
                        {validator: validatePass, trigger: 'blur'}
                    ],
                    sifre2: [
                        {validator: validatePass2, trigger: 'blur'}
                    ],
                }
            }
        },
        methods: {
            dashboardKaydet(){
                this.$store.commit('changeDashboard', this.dashboardWidget);
                EventBus.$emit("dashboardAyar", true);
                this.ayarDrawer = false;
                notification.Status('success', this, 'Ayarlar kaydedildi');
            },
            goruntuDegistir() {
                $('body').toggleClass('karanlik')
                var deger = $('.toggle--checkbox').prop('checked')
                this.$store.commit('changeGorunum', deger);
            },
            editPass(formName) {
                if(this.validateForm(formName)){
                    yonetimService.sifreGuncelle(this.form.eskisifre, this.form.sifre).then((response) => {
                        if (response.status === 200) {
                            notification.Status('success', this, 'Şifre başarılı bir şekilde güncellendi. Lütfen yeni şifreniz ile giriş yapınız.');
                            this.$store.commit('setLogout');
                            this.$router.push("/login");
                            var tabList = [];
                            this.$store.commit('changeTabList', tabList);
                            this.$store.commit('changeNavVisible', false);
                            localStorage.removeItem("userDataDemirag");
                        }
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                            notification.Status(150, this);
                        }
                        else notification.Status("errorMsg", this, error.errMsg)
                        }
                        this.detailLoading = false;
                    })
                }
            },

            onCommand(path) {
                if (path === "sifre") {
                    this.drawer = true;
                } else if (path === "profile") {
                    var name = "Profile";
                    var obj = this.tabList.find(data => data.title === name);
                    if (obj === undefined) {
                        this.tabList.push({
                            title: name,
                            name:name,
                            contentName: functions.linkTemizle(name.replace(" ", ""))
                        });
                        this.$store.commit('changeTabList', this.tabList);
                        this.$store.commit('changeActiveTabs', name);
                    } else {
                        this.$store.commit('changeActiveTabs', name);
                    }
                } else{
                    var tabList = [];
                    this.$store.commit('changeTabList', tabList);
                    this.$store.commit('changeNavVisible', false);
                    localStorage.removeItem("userDataDemirag");
                    this.$router.push(path)
                }

            },
            toggleSidebar() {
                this.$emit('toggle-sidebar')
            },
            route(title) {
                var tabList = this.$store.state.tabList;
                var obj = tabList.find(data => data.title === title);
                var name = linkTemizle(title.replace(" ", ""));
                if (obj === undefined) {
                    tabList.push({
                        title: title,
                        name: name,
                        contentName: name
                    });
                    this.$store.commit('changeTabList', tabList);
                    this.$store.commit('changeActiveTabs', name);
                } else {
                    this.$store.commit('changeActiveTabs', name);
                }
            },
            toggleFullscreen() {
                this.$fullscreen.toggle(document.querySelector('body'), {
                    wrap: false,
                    callback: () => {
                        this.fullscreen = this.$fullscreen.getState()
                    }
                })
            }
        },
        mounted() {
            this.menuSecim = this.$store.getters.getMenuSecim;

            if (localStorage.getItem("userDataDemirag")) {
                this.userdata.adSoyad = this.$store.getters.getUserName
            } else {
                this.$store.commit('setLogout');
                this.$router.push("/login");
            }

            if (this.$store.state.layout.karanlikMod === false) {
                $(".toggle--checkbox").prop("checked", false);
                $('body').addClass('karanlik')
            } else {
                $(".toggle--checkbox").prop("checked", true);
            }


        },
    }


</script>

<style lang="scss" scoped>
    @import '../assets/scss/_variables';
    @import '../assets/scss/_mixins';

    

    i.icon-color {
        color: #81AA29;
    }

    .toolbar .username a {
        color: #909399 !important;
        text-decoration: none;
        font-weight: bold !important;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
    }

    .toolbar-scroll{
        max-height: 550px;
        overflow-y: scroll; 
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-webkit-scrollbar-track {
            background: #f3f7fa;
        }
        &::-webkit-scrollbar-thumb {
            background: #e3eaf0;
        }
    }


    .toolbar {
        width: 100%;
        height: 100%;
        padding: 0 20px;
        box-sizing: border-box;

        .username {
            margin-left: 20px;
            font-weight: bold;
            @include text-bordered-shadow();

            a {
                color: $text-color;
                text-decoration: none;
                transition: all 0.5s;

                &:hover {
                    font-weight: unset;
                    -webkit-mask-image: linear-gradient(-75deg, rgba(0, 0, 0, .6) 30%, #000 50%, rgba(0, 0, 0, .6) 70%);
                    -webkit-mask-size: 200%;
                    animation: shine 2s infinite;
                }
            }
        }

        @-webkit-keyframes shine {
            from {
                -webkit-mask-position: 150%;
            }

            to {
                -webkit-mask-position: -50%;
            }
        }

        .nav-avatar {
            border-radius: 50%;
            width: 35px;
            height: 35px;
            border: 1px solid #FFF;
            margin-left: 20px;
            box-sizing: border-box;
            display: block;
            cursor: pointer;
            box-shadow: 0 2px 5px 0 rgba(49, 49, 93, .1), 0 1px 2px 0 rgba(0, 0, 0, .08);
            transition: box-shadow .5s;

            &:hover {
                box-shadow: 0px 3px 10px 0 rgba(49, 49, 93, 0.08), 0px 2px 7px 0 rgba(0, 0, 0, 0.08);
            }
        }

        .notification-icon {
            font-size: 20px;
            outline: none;
            padding: 0;
            background: transparent;
            border: none;
            margin-left: 20px;
            //color: #aab7c5;
            color: transparentize($text-color, 0.7);
            @include text-bordered-shadow();

            &:hover {
                color: $text-color-accent;
            }
        }

        .toggle-sidebar {
            border: 1px solid transparent;
            height: 32px;
            min-height: 32px;
            line-height: 32px;
            width: 32px;
            min-width: 32px;
            max-width: 32px;
            box-sizing: border-box;
            text-align: center;
            margin: 0;
            outline: none;
            margin-right: 5px;
            font-size: 24px;
            padding: 0;
            cursor: pointer;
            display: inline-block;
            color: $text-color;
            background: #e3eaf0;
            display: none;
            opacity: 0;
            transition: all .5s;

            &__right {
                margin-right: 0px;
                margin-left: 5px;
            }

            &:hover, &:focus, &:active {
                color: $text-color-accent;
                border-color: $text-color-accent;
            }
        }

        .fullscreen-button {
            margin-top: 0;
            padding: 10px 0 0 0;
            font-size: 36px;
            cursor: pointer;
            outline: none;
            background: transparent;
            border: none;
            margin-left: 10px;
            margin-right: 10px;
            color: $color-first;
            transition: 500ms all ease-in-out;
            //color: transparentize($text-color, 0.7);
            @include text-bordered-shadow();

            &:hover {
                color: $text-color-accent;
                transition: 500ms all ease-in-out;
            }
        }

        .el-button {
            margin-left: 0;
        }

        .el-dropdown {
            .flag-icon {
                box-shadow: 0 2px 5px 0 rgba(49, 49, 93, .1), 0 1px 2px 0 rgba(0, 0, 0, .08);
                cursor: pointer;
                border: 1px solid lighten($background-color, 20%);
                background-color: lighten($background-color, 20%);
            }
        }
    }

    @media (max-width: 650px) {
        .toolbar {
            .username {
                display: none;
            }
        }
    }

    @media (max-width: 768px) {
        .toolbar {
            padding: 0 10px;

            .toggle-sidebar {
                display: block;
                opacity: 1;
            }

            .fullscreen-button {
                display: none;
            }
        }

        .toolbar-scroll{
            max-height: 350px; 
            overflow-y: scroll; 
            overflow-x: hidden;
        }
    }


    .mainDiv {
        .label-switch-box {
            display: block;
            clear: both;
            width: 100%;
            text-align: right;
            margin-bottom: 20px;
        }

        .col-p {
            padding: 0 10px;
            box-sizing: border-box;
        }

        .select-wide {
            width: 100%;
        }
    }

    .guncellemeButon {
        cursor: pointer;
        color: $color-first;
        text-shadow: 0px 0px 1px #f3f7fa, 0px 0px 1px #f3f7fa, 0px 0px 1px #f3f7fa, 0px 0px 1px #f3f7fa, 0px 0px 1px #f3f7fa, 0px 0px 1px #f3f7fa, 0px 0px 1px #f3f7fa, 0px 1px 2px rgba(0,0,0,.3), 0px 3px 9px rgba(0,0,0,.1);
    }

    .ayarlarButon {
        border: none;
        color: $color-first;
        font-size: 24px;
        padding-top: 10px;
        cursor: pointer;
        transition: 300ms all ease-in-out;
        background: transparent;
        @include text-bordered-shadow();
        i {
            width: 24px;
            height: 24px;
            display: inline-block;
            transition: all 300ms ease-in-out;
        }

        &:hover i {
            transform: rotate(180deg);
        }
    }


    .guncellemeButon:hover {
        color: $color-first;
        transform: rotate(180deg);
    }
    .guncellemeButon i::before{
        transition: 500ms all ease-in-out;
    }
    .guncellemeButon:hover i::before{
        transform: rotate(360deg);
        transition: 500ms all ease-in-out;
    }


    .toggle {
        display: block;
        text-align: center;
        margin-top: 0;
        user-select: none;
    }
    .toggle--checkbox {
        display: none;
    }
    .toggle--btn {
        display: block;
        margin: 0 auto;

        font-size: 1.4em;

        transition: all 350ms ease-in;

        &:hover {
            cursor: pointer;
        }
    }

    .toggle--btn {
        overflow: hidden;
    }
    .toggle--btn,
    .toggle--checkbox,
    .toggle--feature {
        &,
        &:before,
        &:after {
            transition: all 250ms ease-in;
        }
        &:before,
        &:after {
            content: '';
            display: block;
        }
    }

    /* ===================================================
       Day/Night and Gender toggle buttons
       =================================================== */

    $toggleHeight: 30px;
    $toggleWidth: 70px;
    $borderWidth: 2px;
    $toggleBtnRadius: $toggleHeight - 14px;

    $bgColor--night: #3c4145;
    $borderColor--night: #1c1c1c;
    $toggleBtn-bgColor--night: #fff;
    $toggleBtn-borderColor--night: #e3e3c7;

    $bgColor--day: #9ee3fb;
    $borderColor--day: #86c3d7;
    $toggleBtn-bgColor--day: #ffdf6d;
    $toggleBtn-borderColor--day: #e1c348;

    $cloud-borderColor: #d3d3d3;
    $cloud-bgColor: #fff;

    .toggle--daynight,
    .toggle--like {
        .toggle--btn {
            position: relative;
            height: $toggleHeight;
            width: $toggleWidth;
            border-radius: $toggleHeight;

            //toggle button
            &:before {
                position: absolute;
                top: 5px;
                left: 4px;
                width: $toggleBtnRadius;
                height: $toggleBtnRadius;
                border-radius: 50%;
            }
        }
    }

    .toggle--daynight {
        .toggle--btn {
            border: $borderWidth solid $borderColor--night;
            background-color: $bgColor--night;

            //toggle button
            &:before {
                background-color: $toggleBtn-bgColor--night;
                border: $borderWidth solid $toggleBtn-borderColor--night;
            }
            &:after {
                position: absolute;
                top: 62%;
                left: $toggleWidth - ($toggleBtnRadius) - ($borderWidth * 2) - 20px;
                z-index: 10;
                width: $toggleBtnRadius / 5;
                height: $toggleBtnRadius / 5;
                opacity: 0;
                background-color: $cloud-bgColor;
                border-radius: 50%;

                //crazy ass box-shadow to make the cloud
                box-shadow: $cloud-bgColor 0 0,
                $cloud-bgColor 3px 0,
                $cloud-bgColor 6px 0,
                $cloud-bgColor 9px 0,
                $cloud-bgColor 11px 0,
                $cloud-bgColor 14px 0,
                $cloud-bgColor 16px 0,
                $cloud-bgColor 21px -1px 0 1px,
                $cloud-bgColor 16px -7px 0 -2px,
                $cloud-bgColor 7px -7px 0 1px,

                $cloud-borderColor 0 0 0 4px,
                $cloud-borderColor 6px 0 0 4px,
                $cloud-borderColor 11px 0 0 4px,
                $cloud-borderColor 16px 0 0 4px,
                $cloud-borderColor 21px -1px 0 5px,
                $cloud-borderColor 16px -7px 0 1px,
                $cloud-borderColor 7px -7px 0 5px;

                transition: opacity 100ms ease-in;
            }
        }

        @keyframes starry_star {
            50% {
                background-color: rgba(255,255,255,0.1);
                box-shadow: #fff 30px -3px 0 0,
                #fff 12px 10px 0 -1px,
                rgba(255,255,255,0.1) 38px 18px 0 1px,
                #fff 32px 34px 0 0,
                rgba(255,255,255,0.1) 20px 24px 0 -1.5px,
                #fff 5px 38px 0 1px;
            }
        }
        @keyframes bounceIn {
            0% {
                opacity: 0;
                transform: scale(.3);
            }
            50% {
                opacity: 100;
                transform: scale(1.1);
            }
            55% {
                transform: scale(1.1);
            }
            75% {
                transform: scale(.9);
            }
            100% {
                opacity: 100;
                transform: scale(1);
            }
        }
        //stars
        .toggle--feature {
            display: block;
            position: absolute;
            top: 9px;
            left: 32.5%;
            z-index: 20;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: #fff;

            box-shadow: rgba(255,255,255,0.1) 30px -3px 0 0,
            rgba(255,255,255,0.1) 12px 10px 0 -1px,
            #fff 38px 18px 0 1px,
            rgba(255,255,255,0.1) 32px 34px 0 0,
            #fff 20px 24px 0 -1.5px,
            rgba(255,255,255,0.1) 5px 38px 0 1px;

            animation: starry_star 5s ease-in-out infinite;

        }

        //what happens once checkbox is checked
        .toggle--checkbox:checked {
            & + .toggle--btn {
                background-color: $bgColor--day;
                border: $borderWidth solid $borderColor--day;

                &:before {
                    left: $toggleWidth - ($toggleBtnRadius) - ($borderWidth * 2) - 4px;
                    background-color: $toggleBtn-bgColor--day;
                    border: $borderWidth solid $toggleBtn-borderColor--day;
                }
                &:after {
                    opacity: 100;
                    animation-name: bounceIn;
                    animation-duration: 0.60s;
                    animation-delay: 0.10s;
                    animation-fill-mode: backwards;
                    animation-timing-function: ease-in-out;
                }

                > .toggle--feature {
                    opacity: 0;
                    box-shadow: rgba(255,255,255,0.1) 30px -3px 0 -4px,
                    rgba(255,255,255,0.1) 12px 10px 0 -5px,
                    #fff 38px 18px 0 -3px,
                    rgba(255,255,255,0.1) 32px 34px 0 -4px,
                    #fff 20px 24px 0 -5.5px,
                    rgba(255,255,255,0.1) 5px 38px 0 -3px;
                    animation: none;

                    &:before {
                        left: 25px;
                        transform: rotate(70deg);
                    }
                }
            }
        }
    }


    .dividerAyar {
        margin-top: 0 !important;

        .el-divider__text {
            font-size: 14px !important;
        }
    }
</style>
