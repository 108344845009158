<template>
	<div class="page-profile" id="affix-container" style="margin-top: 10px">
		<div class="card-base identity" id="boundary">
			<div class="cover"></div>
			<div class="username">
				<div class="cover-small"></div>
				<span>{{userDataDemirag.adSoyad}}</span>
				<div class="colors-box">
					<div v-for="i in 5" :key="i" :class="{'color':true, 'active':colorActive}"
						 :style="{'background':color}"></div>
				</div>
			</div>
			<div class="profile-avatar"><img v-if="userDataDemirag.resim" :src="imageUrl+userDataDemirag.resim" alt="avatar">
				<img v-else src="@/assets/images/avatar.jpg" alt="avatar"></div>
			<img src="@/assets/images/cover-2.jpg" id="color-thief" class="color-thief" alt="profile cover">
		</div>
		<el-form class="profil" status-icon :model="profilForm" :rules="rulesProfil" ref="profilForm">
			<div class="flip-card" id="flipFirma">
				<div class="flip-card-inner">
					<div class="card-base card-shadow--medium">
						<div class="flip-card-front" v-if="!editProfile">
							<el-button v-on:click="edit(true)" type="warning" size="mini"
									   icon="el-icon-edit"></el-button>
							<el-divider content-position="left">{{$t("src.views.apps.profil.baslik")}}</el-divider>
							<div class="flex center demo-box">
								<div class="right-box box grow">
									<dl class="horizontal">
										<dt><i class="mdi mdi-city"></i>{{$t("src.views.apps.profil.adSoyad")}}
										</dt>
										<dd>: {{userDataDemirag.adSoyad}}</dd>
										<el-divider></el-divider>
										<!-- <dt><i class="mdi mdi-city"></i>{{$t("src.views.apps.profil.tcNo")}}
										</dt>
										<dd>: {{userDataDemirag.TC}}</dd> 
										<el-divider></el-divider> -->
										<dt><i class="mdi mdi-city"></i>{{$t("src.views.apps.profil.kullaniciAdi")}}
										</dt>
										<dd>: {{userDataDemirag.yoneticiAdi}}</dd>
										<el-divider></el-divider>
										<dt><i class="mdi mdi-city"></i>{{$t("src.views.apps.profil.cepTelefon")}}
										</dt>
										<dd>: {{userDataDemirag.cepTelefonu}}</dd>
										<el-divider></el-divider>
										<dt><i class="mdi mdi-city"></i>{{$t("src.views.apps.profil.sabitTelefon")}}
										</dt>
										<dd>: {{userDataDemirag.sabitTelefon}}</dd>
										<el-divider></el-divider>
										<dt><i class="mdi mdi-city"></i>{{$t("src.views.apps.profil.dahili")}}
										</dt>
										<dd>: {{userDataDemirag.dahiliTelefon}}</dd>
									</dl>
								</div>
							</div>
						</div>
						<div v-if="editProfile">
							<div class="btnBack">
								<el-button v-on:click="profilGuncelle('formName')"
										   type="success"
										   size="mini"
										   icon="el-icon-check"></el-button>
								<el-button v-on:click="edit(false)" type="danger" size="mini"
										   icon="el-icon-close"></el-button>
							</div>
							<div class="col-p">
								<el-form-item
										:label='$t("src.views.apps.profil.adSoyad")'
										prop="adSoyad">:
									<el-input v-model="profilForm.adSoyad"></el-input>
								</el-form-item>
							</div>
							<!--<div class="col-p">
								<el-form-item
										:label='$t("src.views.apps.profil.tcNo")'
										prop="tcNo">:
									<el-input v-model="profilForm.TC" v-mask="'###########'"></el-input>
								</el-form-item>
							</div>
							<div class="col-p">
								<el-form-item
										:label='$t("src.views.apps.profil.kullaniciAdi")'
										prop="kullaniciAdi">:
									<el-input v-model="profilForm.yoneticiAdi"></el-input>
								</el-form-item>
							</div> -->
							<div class="col-p">
								<el-form-item
										:label='$t("src.views.apps.profil.cepTelefon")'
										prop="cepTelefon">:
									<el-input v-mask="'+90(###)### ## ##'" v-model="profilForm.cepTelefonu"></el-input>
								</el-form-item>
							</div>
							<div class="col-p">
								<el-form-item
										:label='$t("src.views.apps.profil.sabitTelefon")'
										prop="sabitTelefon">:
									<el-input v-mask="'+90(###)### ## ##'" v-model="profilForm.sabitTelefon"></el-input>
								</el-form-item>
							</div>
							<div class="col-p">
								<el-form-item
										:label='$t("src.views.apps.profil.dahili")'
										prop="dahili">:
									<el-input v-mask="'#####'" v-model="profilForm.dahiliTelefon"></el-input>
								</el-form-item>
							</div>
						</div>
					</div>
				</div>
			</div>
		</el-form>
	</div>
</template>

<script>
	import yonetimService from '../../WSProvider/YoneticiService'
	import ColorThief from 'color-thief-browser'
	import Affix from '@/components/Affix'
	import JQuery from 'jquery';
	import notification from '../../notification'
	import jwt_decode from "jwt-decode"
	import EventBus from "@/components/event-bus";

	let $ = JQuery;
	let userDataDemirag = "";

	export default {
		name: 'Profile',
		data() {
			return {
				editProfile: false,
				imageUrl: yonetimService.imagePath,
				userDataDemirag: {},
				updateData: [],
				colorActive: false,
				color: 'white',
				affixEnabled: true,
				profilForm: {
					yoneticiID : '',
					adSoyad : '',
					TC : '',
					yoneticiAdi : '',
					cepTelefonu : '',
					sabitTelefon : '',
					dahiliTelefon : '',
				},
				rulesProfil: {}
			}
		},
		methods: {
			edit(value){
				if(value === false){
					this.editProfile = false;
				}
				else if(value === true){
					this.profilForm.adSoyad = this.userDataDemirag.adSoyad
					this.profilForm.TC = this.userDataDemirag.TC
					this.profilForm.yoneticiAdi = this.userDataDemirag.yoneticiAdi
					this.profilForm.cepTelefonu = this.userDataDemirag.cepTelefonu
					this.profilForm.sabitTelefon = this.userDataDemirag.sabitTelefon
					this.profilForm.dahiliTelefon = this.userDataDemirag.dahiliTelefon
					this.editProfile = true;
				}
			},
			yoneticiDetay(){
				yonetimService.getAccount().then((response)=>{
					if(response.status === 200){
						localStorage.setItem("userDataDemirag", response.token)
						this.userDataDemirag = response.data;
					}
					else if(response.status === 401){
                        notification.Status("warning", this, "Oturum süreniz sona ermiştir.Tekrar giriş yapınız.")
                        EventBus.$emit("userLogout", true)
                    } 
                    else if(response.status === 403){
                        notification.Status("warning", this, "Bu işlem için yetkiniz bulunmamaktadır.")
                    }
				})
			},
			profilGuncelle(formName) {
				let self = this;
				//yoneticiID, resim, yoneticiAdi, adSoyad, cepTelefonu, sabitTelefon, dahiliTelefon
				yonetimService.yoneticiGuncelle(this.profilForm.yoneticiID, "", this.profilForm.adSoyad, this.profilForm.cepTelefonu, this.profilForm.sabitTelefon, this.profilForm.dahiliTelefon).then(response => {
					 if (response.data.status === 200) {
						 localStorage.setItem("userDataDemirag", response.data.token)
                        notification.Status('success', this, 'Profil başarıyla güncellendi.');
                        EventBus.$emit("sayfaKapat",true, "updateadmin")
						this.edit(false)
						this.yoneticiDetay()
                    } else if (response.data.status === 230) {
                        notification.Status('warning', this, 'Bu bilgiler ile kayıtlı bir sayfa zaten mevcut');
                    } else {
                        notification.Status(response.status, this);
                    }
				})
				
			},


			resizeAffixEnabled() {
				if (window.innerWidth <= 768) {
					this.affixEnabled = false
				} else {
					this.affixEnabled = true
				}
			}
		},
		mounted() {
			let state = false;
			if (localStorage.getItem("userDataDemirag")) {
				userDataDemirag = jwt_decode(localStorage.getItem("userDataDemirag"));
				this.profilForm.yoneticiID = userDataDemirag["yoneticiID"]
				if (userDataDemirag["yetkiTuru"]) {
					if (userDataDemirag["yetkiTuru"] === '1' || userDataDemirag["yetkiTuru"] === '2')
						state = true;
				}
				// console.log(userDataDemirag);
			}
			if (!state) {
				this.$store.commit('setLogout');
				this.$router.push("/login");
			}else{
				this.yoneticiDetay()
			}
			var colorThief = new ColorThief();
			setTimeout(() => {
				let rgb = colorThief.getColor(document.getElementById('color-thief'))
				//console.log('Profile mounted', rgb)
				this.colorActive = true
				this.color = `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`
			}, 1000)

			this.resizeAffixEnabled();
			window.addEventListener('resize', this.resizeAffixEnabled);
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.resizeAffixEnabled);
		},
		components: {
			Affix,
		}
	}
</script>

<style lang="scss" scoped>
	@import '../../assets/scss/_variables';

	.page-profile {
		overflow: hidden;

		.identity {
			margin-bottom: 20px;
			position: relative;
			width: 100%;
			height: 210px;

			.cover {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-image: url('../../assets/images/cover-2.jpg');
				background-position: 50%;
				background-size: cover;
				background-repeat: no-repeat;
				width: 100%;
				height: 100%;
			}

			.username {
				color: #32325d;
				position: absolute;
				width: 100%;
				height: 50px;
				bottom: 75px;
				left: 0;
				right: 0;
				background: #fff;
				line-height: 50px;
				box-sizing: border-box;
				padding-left: 250px;
				font-size: 25px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);

				.cover-small {
					width: 220px;
					height: 50px;
					overflow: hidden;
					display: block;
					float: left;
					margin-right: -220px;
					position: relative;
					left: -250px;
					border-radius: 9px;
					-webkit-box-sizing: border-box;
					box-sizing: border-box;
					border: 4px solid white;
					opacity: 0;
					top: 0px;
					background-image: url('../../assets/images/cover-2.jpg');
					background-position: 50%;
					background-size: cover;
					background-repeat: no-repeat;
					-webkit-transition: all .5s;
					transition: all .5s;
				}

				.avatar-small {
					width: 50px;
					height: 50px;
					overflow: hidden;
					display: block;
					float: left;
					margin-right: -50px;
					position: relative;
					left: -100px;
					border-radius: 50%;
					box-sizing: border-box;
					border: 4px solid white;
					opacity: 0;
					top: 0px;
					transform: rotate(-50deg);
					transition: all .5s;
				}

				.avatar-small img {
					width: 100%;
				}

				&.affix {
					z-index: 99;
					border-radius: 5px;

					.cover-small {
						opacity: 1;
					}

					.avatar-small {
						opacity: 1;
						left: -60px;
						transform: rotate(0deg);
					}
				}

				.colors-box {
					height: 50px;
					background: white; //091c2d
					float: right;

					.color {
						width: 50px;
						height: 50px;
						background: white; //091c2d
						float: right;
						transform: skew(-45deg);
						box-shadow: 1px 0px 1px 0px transparent;
						position: relative;
						right: -25px;
						margin-right: -50px;
						transition: margin-right .75s;

						&.active {
							margin-right: 0;
						}

						&:nth-child(2) {
							opacity: .8;
						}

						&:nth-child(3) {
							opacity: .6;
						}

						&:nth-child(4) {
							opacity: .4;
						}

						&:nth-child(5) {
							opacity: .2;
						}
					}
				}
			}

			.profile-avatar {
				border: 6px solid #fff;
				position: absolute;
				bottom: 10px;
				left: 50px;
				width: 180px;
				height: 180px;
				overflow: hidden;
				border-radius: 50%;
				box-sizing: border-box;
				box-shadow: 0px 20px 15px -15px rgba(0, 0, 0, 0.15);

				img {
					width: 100%;
				}
			}

			.color-thief {
				display: block;
				width: 100px;
				visibility: hidden;
				z-index: -999999;
				position: absolute;
			}
		}

		.info {
			padding: 15px 20px;
		}
	}

	@media (max-width: 768px) {
		.page-profile {
			.identity {
				height: auto;

				.avatar {
					bottom: inherit;
					top: 10px;
					left: 50%;
					width: 100px;
					margin-left: -50px;
					height: 100px;
					border-width: 3px;
				}

				.username {
					position: inherit;
					padding: 10px;
					margin: 0;
					top: inherit;
					left: inherit;
					z-index: 1;
					right: inherit;
					text-align: center;
					bottom: inherit;
					white-space: inherit;
					line-height: inherit;
					height: auto;
					margin-top: 120px;
					width: 90%;
					margin-left: 5%;
					margin-bottom: 10px;
					border-radius: 50px;

					.colors-box {
						display: none;
					}

					.avatar-small {
						display: none;
					}

					.cover-small {
						display: none;
					}
				}
			}

			.info {
				padding: 8px 16px;
			}
		}
	}
</style>

<style lang="scss">
	.page-profile {
		.el-tabs:not(.el-tabs--border-card) {
			.el-tabs__item:not(.is-active) {
				color: #32325d;
			}
		}
	}

	#flipFirma, #flipSite {
		width: 100%;
		height: auto;
		perspective: 2500px;
	}

	.flip-card-inner {
		position: relative;
		width: 100%;
		height: 100%;
		/*text-align: center;*/
		transition: transform 0.8s;
		transform-style: preserve-3d;
	}

	.flip-card.flipac .flip-card-inner {
		transform: rotateY(180deg);
	}

	.flip-card-front {
		width: 100%;
		height: 100%;
		backface-visibility: hidden;
		-webkit-backface-visibility: hidden;
	}

	.flip-card-back {
		position: absolute;
		transform: rotateY(-180deg) translateZ(1px);
		top: 0;
		left: 0;
		width: 100%;
		/*height: 100%;*/
		background: #fff;
		backface-visibility: hidden;
		-webkit-backface-visibility: hidden;
	}

	.flip-card-front .el-button {
		display: block;
		margin-left: auto;
		margin-top: 20px;
		margin-right: 20px;
	}

	.btnBack {
		display: flex;
		justify-content: flex-end;
		position: relative;
		top: 10px;
		right: 10px;
		z-index: 100;
	}

	dl i {
		color: #0075c3;
		margin: 0 10px;
	}

	.el-divider--horizontal {
		margin: 22px 0;
	}

	dl.horizontal dt {
		text-align: left;
		width: 30%;
	}

	dl.horizontal dd {
		margin-left: unset;
	}
	.profil .el-form-item__content {
		line-height: 30px;
		position: relative;
		font-size: 14px;
		margin: 0 20px;
	}
	.profil .el-form-item__label {
		line-height: 30px !important;
		margin: 0 20px;
	}
</style>
