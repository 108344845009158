import Vue from 'vue'
import Router from 'vue-router'


//apps
import Dashboard from '../views/apps/Dashboard.vue'
import Firma from './firma'
import Sayfa from './sayfa'
import Bulten from './bulten'
import Pop from './pop'
import Iletisim from './iletisim'
import Slider from './slider'
import Galeri from './galeri'
import SSS from "./sss"
import Menu from "./menu"
import Belge from "./belge"
import Urun from './urun'
import Hizmet from './hizmet'
import Rakam from './rakam'
import Referans from './referans'
import Cari from './cari'
import Evrak from './evrak'

//pages
import Login from '../views/pages/authentication/Login.vue'
import Register from '../views/pages/authentication/Register.vue'
import ForgotPassword from '../views/pages/authentication/ForgotPassword.vue'
import Profile from '../views/pages/Profile.vue'
import NotFound from '../views/pages/NotFound.vue'

import layouts from '../layout'
import store from '../store'

Vue.use(Router)
Vue.component('profile', Profile);


const router = new Router({
    mode: 'history',
    //base: '/demirag/panel/',
    base: '/',
    routes: [{
            path: '/',
            alias: '/dashboard',
            name: 'dashboard',
            component: Dashboard,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                searchable: true,
                tags: ['app']
            }
        },
        {
            path: '/profile',
            name: 'profile',
            component: Profile,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                searchable: true,
                tags: ['pages']
            }
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                layout: layouts.contenOnly
            }
        },
        {
            path: '/register',
            name: 'register',
            component: Register,
            meta: {
                layout: layouts.contenOnly
            }
        },
        {
            path: '/sifremi-unuttum',
            name: 'sifremi-unuttum',
            component: ForgotPassword,
            meta: {
                layout: layouts.contenOnly
            }
        },
        {
            path: '/logout',
            beforeEnter(to, from, next) {
                localStorage.removeItem("userDataDemirag");
                auth.logout()
                next({ path: '/login' })
            }
        },
        Firma,
        Sayfa,
        Pop,
        Iletisim,
        Bulten,
        Slider,
        Galeri,
        SSS,
        Menu,
        Belge,
        Urun,
        Hizmet,
        Rakam,
        Referans,
        Cari,
        Evrak,
        {
            path: '*',
            name: 'not-found',
            component: NotFound,
            meta: {
                layout: layouts.contenOnly
            }
        }
    ]
})


const l = {
    contenOnly() {
        store.commit('setLayout', layouts.contenOnly)
    },
    set(layout) {
        store.commit('setLayout', layout)
    }
}

const auth = {
    loggedIn() {
        return store.getters.isLogged
    },
    logout() {
        store.commit('setLogout')
    }
}

//insert here login logic
router.beforeEach((to, from, next) => {
    let authrequired = false
    if (to && to.meta && to.meta.auth)
        authrequired = true

    //console.log('authrequired', authrequired, to.name)

    if (authrequired) {
        if (auth.loggedIn()) {
            if (to.name === 'login') {
                window.location.href = '/'
                return false
            } else {
                next()
            }
        } else {
            if (to.name !== 'login') {
                window.location.href = '/login'
                return false
            }
            next()
        }
    } else {
        if (auth.loggedIn() && to.name === 'login') {
            window.location.href = '/'
            return false
        } else {
            next()
        }
    }

    if (to && to.meta && to.meta.layout) {
        l.set(to.meta.layout)
    }
})

router.afterEach((to, from) => {
    setTimeout(() => {
        store.commit('setSplashScreen', false)
    }, 500)
})

export default router