const Iletisim = resolve => {
    require.ensure(['../views/apps/iletisim/Iletisim'], () => {
        resolve(require('../views/apps/iletisim/Iletisim'))
    })
};
const IletisimListesi = resolve => {
    require.ensure(['../views/apps/iletisim/IletisimListesi'], () => {
        resolve(require('../views/apps/iletisim/IletisimListesi'))
    })
};


import Vue from 'vue'
Vue.component('iletisimlistesi', IletisimListesi);

import layouts from '../layout'

export default {
    path: '/iletisim',
    name: 'iletisim',
    component: Iletisim,
    meta: {
        layout: layouts.navLeft
    },
}