const Referans = resolve => {
    require.ensure(['../views/apps/referans/Referans'], () => {
        resolve(require('../views/apps/referans/Referans'))
    })
};
const ReferansListe = resolve => {
    require.ensure(['../views/apps/referans/ReferansListe'], () => {
        resolve(require('../views/apps/referans/ReferansListe'))
    })
};


import Vue from 'vue'

Vue.component('referanslar', ReferansListe);

import layouts from '../layout'

export default {
    path: '/referanslar',
    name: 'referanslar',
    component: Referans,
    meta: {
        auth: true,
        layout: layouts.navLeft
    },
}