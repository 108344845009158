<template>
    <div class="page-dashboard scrollable only-y">
        <resize-observer @notify="__resizeHanlder"/>
        
        <div class="page-header" v-loading="loading"
        element-loading-text="Evrak Listesi Getiriliyor. Lütfen Bekleyiniz."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(243,247,250,255)">
        <el-collapse v-model="activeNames">

           
            <el-badge :value="bekleyenGelenCount" style="z-index: 999;" class="item anasayfa" v-if="bekleyenGelenCount != 0"></el-badge>
            <el-row :gutter="20" v-if="bekleyenGelenCount != 0">
                <el-col :lg="24" :md="24 / this.altSayi" :sm="24" :xs="24" class="margin-bottom-20" v-if="islemGorunum">
                   
                    <el-collapse-item class="animated fadeInUp" title="Gelen Bekleyen Evraklar" name="1">
                        <div class="scrollable only-y" style="max-height: 260px !important;">
                            <table class="styled striped animated fadeIn delay-200ms">
                                <thead>
                                    <tr>
                                        <th style="min-width:80px;">Evrak No</th>
                                        <th style="min-width:180px;">Evrak Konu</th>
                                        <th style="min-width:180px;">Cari Adı</th>
                                        <th style="min-width:180px;">Evrak Tarihi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in bekleyenGelen" :key="index" class="satir">
                                        <td style="min-width:80px;">{{formatEvrakNo(item)}}</td>
                                        <td style="min-width:180px;">{{item.konu}}</td>
                                        <td style="min-width:180px;">{{item.kurumAdi}}</td>
                                        <td style="min-width:180px;">{{formatDate(item.islemTarihi)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </el-collapse-item>
                </el-col>
            </el-row>

            <el-badge :value="bekleyenGidenCount" style="z-index: 999;" class="item anasayfa" v-if="bekleyenGidenCount != 0"></el-badge>
            <el-row :gutter="20" v-if="bekleyenGidenCount != 0">
                <el-col :lg="24" :md="24 / this.altSayi" :sm="24" :xs="24" class="margin-bottom-20" v-if="islemGorunum">
                    <el-collapse-item class="animated fadeInUp" title="Giden Bekleyen Evraklar" name="2">
                        <div class="scrollable only-y" style="max-height: 260px !important;">
                            <table class="styled striped animated fadeIn delay-200ms">
                                <thead>
                                    <tr>
                                        <th style="min-width:80px;">Evrak No</th>
                                        <th style="min-width:180px;">Evrak Konu</th>
                                        <th style="min-width:180px;">Cari Adı</th>
                                        <th style="min-width:180px;">Evrak Tarihi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in bekleyenGiden" :key="index" class="satir">
                                        <td style="min-width:80px;">{{formatEvrakNo(item)}}</td>
                                        <td style="min-width:180px;">{{item.konu}}</td>
                                        <td style="min-width:180px;">{{item.kurumAdi}}</td>
                                        <td style="min-width:180px;">{{formatDate(item.islemTarihi)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </el-collapse-item>
                </el-col>
            </el-row>
        
            <el-badge :value="tamamlananGelenCount" style="z-index: 999;" class="item anasayfa" v-if="tamamlananGelenCount != 0"></el-badge>
            <el-row :gutter="20"  v-if="tamamlananGelenCount != 0">
                <el-col :lg="24" :md="24 / this.altSayi" :sm="24" :xs="24" class="margin-bottom-20" v-if="islemGorunum">
                    <el-collapse-item class="animated fadeInUp" title="Gelen Tamamlanan Evraklar" name="3">
                        <div class="scrollable only-y" style="max-height: 260px !important;">
                            <table class="styled striped animated fadeIn delay-200ms">
                                <thead>
                                    <tr>
                                        <th style="min-width:80px;">Evrak No</th>
                                        <th style="min-width:180px;">Evrak Konu</th>
                                        <th style="min-width:180px;">Cari Adı</th>
                                        <th style="min-width:180px;">Evrak Tarihi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in tamamlananGelen" :key="index" class="satir">
                                        <td style="min-width:80px;">{{formatEvrakNo(item)}}</td>
                                        <td style="min-width:180px;">{{item.konu}}</td>
                                        <td style="min-width:180px;">{{item.kurumAdi}}</td>
                                        <td style="min-width:180px;">{{formatDate(item.islemTarihi)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </el-collapse-item>
                </el-col>
            </el-row>

            <el-badge :value="tamamlananGidenCount" style="z-index: 999;" class="item anasayfa" v-if="tamamlananGidenCount != 0"></el-badge>
            <el-row :gutter="20" v-if="tamamlananGidenCount != 0">
                <el-col :lg="24" :md="24 / this.altSayi" :sm="24" :xs="24" class="margin-bottom-20" v-if="islemGorunum">
                    <el-collapse-item class="animated fadeInUp" title="Giden Tamamlanan Evraklar" name="4">
                        <div class="scrollable only-y" style="max-height: 260px !important;">
                            <table class="styled striped animated fadeIn delay-200ms">
                                <thead>
                                    <tr>
                                        <th style="min-width:80px;">Evrak No</th>
                                        <th style="min-width:180px;">Evrak Konu</th>
                                        <th style="min-width:180px;">Cari Adı</th>
                                        <th style="min-width:180px;">Evrak Tarihi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in tamamlananGiden" :key="index" class="satir">
                                        <td style="min-width:80px;">{{formatEvrakNo(item)}}</td>
                                        <td style="min-width:180px;">{{item.konu}}</td>
                                        <td style="min-width:180px;">{{item.kurumAdi}}</td>
                                        <td style="min-width:180px;">{{formatDate(item.islemTarihi)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </el-collapse-item>
                </el-col>
            </el-row>
            <!--
            <el-row :gutter="20" v-if="yetkiTuru == 1" style="z-index: 999;">
                <el-col :lg="24 / this.altSayi" :md="24 / this.altSayi" :sm="24" :xs="24" class="margin-bottom-20" v-if="islemGorunum" style="z-index: 999;">
                    <el-collapse-item class="animated fadeInUp" title="İşlem Listesi" name="11">
                        <div class="scrollable only-y" style="max-height: 260px !important;">
                            <table class="styled striped animated fadeIn delay-200ms">
                                <thead>
                                    <tr>
                                        <th style="min-width:80px;">İşlem Tarihi</th>
                                        <th style="min-width:80px;">Yönetici Adı</th>
                                        <th style="min-width:180px;">Açıklama</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in islemList" :key="index" class="satir">
                                        <td style="min-width:80px;">{{item.islemTarihi}}</td>
                                        <td style="min-width:80px;">{{item.adSoyad}}</td>
                                        <td style="min-width:180px;">{{item.aciklama}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </el-collapse-item>
                </el-col>

                
                <el-col :lg="24 / this.altSayi" :md="24 / this.altSayi" :sm="24" :xs="24" class="margin-bottom-20" v-if="yoneticiGorunum" style="z-index: 999;">
                    <el-collapse-item class="animated fadeInUp"  title="Yönetici Listesi" name="12" >
                        <div class="scrollable only-y" style="max-height: 260px !important;">
                            <table class="styled striped animated fadeIn delay-200ms">
                                <thead>
                                    <tr>
                                        <th style="min-width:155px;">Yönetici Adı</th>
                                        <th style="min-width:80px;">Yetki Türü</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in yonetimListe" :key="item.yonetimId" class="satir">
                                        <td style="min-width:85px;">{{item.adSoyad}}</td>
                                        <td style="min-width:80px;">
                                            <span v-if="item.rol == '1'">Tam Yetki</span>
                                            <span v-if="item.rol == '2'">Sınırlı Yetki</span>
                                            <span v-if="item.rol == '3'">Liste Yetki</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </el-collapse-item>
                </el-col>
              
            </el-row>
  -->

        </el-collapse>
        </div>
    </div>
</template>

<script>
    import echarts from 'echarts'
    import notification from '../../notification'
    import Vue from 'vue'
    import excel from 'vue-excel-export'
    import EventBus from '@/components/event-bus'
    import functions from "../../functions";
    import sayfaService from "../../WSProvider/SayfaService";
    import analitikService from "../../WSProvider/AnalitikService"
    import islemService from "../../WSProvider/IslemService"
    import yoneticiService from "../../WSProvider/YoneticiService"
    import dosyaService from '../../WSProvider/DosyaService'
    
    Vue.use(excel);

    export default {
        name: 'Dashboard',
        components:{
        },
        data() {
            return {
                asyncComponent: 'peity',
                activeNames: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15'],

                asyncChart1: false,
                chart1: null,
                pie: null,
                pie2: null,
                pie3: null,
                pie4: null,
                resized: false,

                loading : false,
                islemList: [],
                yonetimListe: [],
                yetkiTuru: "",
                dosyaListe: [],
                bekleyenGelen: [],
                bekleyenGiden: [],
                tamamlananGelen: [],
                tamamlananGiden: [],
                bekleyenGelenCount: "",
                bekleyenGidenCount: "",
                tamamlananGelenCount: "",
                tamamlananGidenCount: "",

                radio1: 'Month',
                radio2: 'Week',
                root: false,
                yetkili: false,
                excelTable: [],

                asyncComponent: 'peity',
                activeNames: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14'],
                sayfaCountGorunum: false,
                siparisCountGorunum: false,
                urunCountGorunum: false,
                musteriCountGorunum: false,
                markaCountGorunum: false,
                kargoCountGorunum: false,
                istatistikGorunum: false,
                islemGorunum: false,
                yoneticiGorunum: false,

                ustSayi: 0,
                altSayi: 0,
                analiticsKey: 0,
                listKey: 0,

                sayilar: {
                    aktifSayfa: '0',
                    pasifSayfa: '0',
                    aktifUrun: '0',
                    pasifUrun: '0',
                    aktifMusteri: '0',
                    pasifMusteri: '0',
                    aktifMarka: '0',
                    pasifMarka: '0',
                    confirmedProduct: '0',
                    waitConfirmProduct: '0',
                    getReadyProduct: '0',
                    cargoProduct: '0',
                    returnProduct: '0',
                    rejectedProduct: '0',
                    cancelProduct: '0',
                }
            }
        },
        methods: {

            formatEvrakNo(row) {
                if (row.kayitKodu) {
                    const parts = row.kayitKodu.split("-");
                    return parts[0];
                } else {
                    return '';
                }
            },

            formatDate(date) {
                if (!date) return "";
                const options = { day: "2-digit", month: "2-digit", year: "numeric" };
                return new Date(date).toLocaleDateString("tr-TR", options);
            },

            getDosyaList() {
                try{
                    this.loading = true;
                    dosyaService.tumDosyaListele().then((response) => {
                        if (response.status == 200) {
                            localStorage.setItem("userDataDemirag", response.token);
                            this.bekleyenGelen = response.bekleyenGelen;
                            this.bekleyenGiden = response.bekleyenGiden;
                            this.tamamlananGelen = response.tamamlananGelen;
                            this.tamamlananGiden = response.tamamlananGiden;

                            this.bekleyenGelenCount = response.bekleyenGelenCount;
                            this.bekleyenGidenCount = response.bekleyenGidenCount;
                            this.tamamlananGelenCount = response.tamamlananGelenCount;
                            this.tamamlananGidenCount = response.tamamlananGidenCount;
                        }
                        this.loading = false;
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else if(error.status == 404){
                                this.bekleyenGelen = [];
                            }
                            else notification.Status("errorMsg", this, error.errMsg)
                        }
                        this.loading = false;
                    })
                }catch(e){
                    this.loading = false;
                }
            },



            __resizeHanlder: _.throttle(function (e) {
                this.resized = true
            }, 700),
            resizeSVG() {
            },
            __resizeHanlder: _.throttle(function (e) {
                if (this.resized) {

                    this.asyncComponent = null;
                    this.removePeity();
                    setTimeout(() => {
                        this.asyncComponent = 'peity'
                    }, 1000);

                    this.asyncChart1 = true;
                    setTimeout(() => {
                        this.asyncChart1 = false;
                        this.resizeChart1();
                        this.resizePie()
                    }, 1000)
                }
                this.resized = true
            }, 700),
            resizeSVG() {
                this.asyncComponent = null;
                this.removePeity();
                setTimeout(() => {
                    this.asyncComponent = 'peity'
                }, 1000)
            },
            removePeity() {
                const peityEl = document.querySelectorAll('.widget .peity');//.forEach((el)=>{el.remove()})
                //ie fix
                for (let i = 0; i < peityEl.length; i++) {
                    peityEl[i].parentNode.removeChild(peityEl[i])
                }
            },
            initChart1(kullaniciData, goruntulenmeData, toplamEtkilesimdata) {
                this.chart1 = echarts.init(document.getElementById('chart1'));

                // Generate data
                let category = [];
                let dottedBase = +new Date();
                for (let i = 0; i < 31; i++) {
                    let date = new Date(dottedBase += 3600 * 24 * 1000);
                    category.push([
                        date.getDate(),
                        date.getMonth(),
                        date.getFullYear()
                    ].join('/'));
                }

                this.chart1.setOption({
                    //backgroundColor: '#0f375f',
                    grid: {
                        show: false,
                        left: '20px',
                        right: '50px',
                        bottom: '20px',
                        top: '20px',
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross'
                        }
                    },
                    legend: {
                        show: false,
                        data: ['line', 'bar'],
                        textStyle: {
                            color: '#ccc'
                        }
                    },
                    xAxis: {
                        data: category,
                        boundaryGap: false,
                        axisLine: {
                            lineStyle: {
                                color: 'rgba(255,255,255,0.5)'
                            }
                        }
                    },
                    yAxis: {
                        splitLine: {show: false},
                        axisLine: {
                            lineStyle: {
                                color: 'rgba(255,255,255,0.5)'
                            }
                        }
                    },
                    series: [
                        {
                            name: 'Number of Users',
                            type: 'line',
                            smooth: true,
                            showAllSymbol: true,
                            symbol: 'emptyCircle',
                            symbolSize: 10,
                            lineStyle: {
                                color: '#fff'
                            },
                            itemStyle: {
                                color: '#fff',
                                borderColor: '#5f8fdf',
                                borderWidth: 3
                            },
                            areaStyle: {
                                color: 'rgba(255,255,255,0.2)'
                            },
                            animationDuration: 2800,
                            animationEasing: 'cubicInOut',
                            data: kullaniciData
                        },
                        {
                            name: 'Number of View',
                            type: 'bar',
                            barWidth: 10,
                            itemStyle: {
                                normal: {
                                    barBorderRadius: 5,
                                    color: new echarts.graphic.LinearGradient(
                                        0, 0, 0, 1,
                                        [
                                            {offset: 0, color: '#fff'},
                                            {offset: 1, color: 'rgba(255,255,255,0)'}
                                        ]
                                    )
                                }
                            },
                            data: goruntulenmeData
                        },
                        {
                            name: 'Total Interaction',
                            type: 'pictorialBar',
                            symbol: 'rect',
                            itemStyle: {
                                normal: {
                                    color: 'rgba(255,255,255,0.1)'
                                }
                            },
                            symbolRepeat: true,
                            symbolSize: [12, 4],
                            symbolMargin: 1,
                            z: -10,
                            data: toplamEtkilesimdata
                        }
                    ]
                })
            },
            initPie(ulkeGelen, sehirGelen, isletimGelen, browserGelen) {
                this.pie = echarts.init(document.getElementById('pie'));
                this.pie2 = echarts.init(document.getElementById('pie2'));
                this.pie3 = echarts.init(document.getElementById('pie3'));
                this.pie4 = echarts.init(document.getElementById('pie4'));
                this.pie.setOption({
                    tooltip: {
                        trigger: 'item',
                        formatter: "<b>{a}</b> <br/>{b}: {c} ({d}%)"
                    },
                    series: [
                        {
                            name: 'Country:',
                            type: 'pie',
                            selectedMode: 'single',
                            data: ulkeGelen,
                        },
                    ]
                });
                this.pie2.setOption({
                    tooltip: {
                        trigger: 'item',
                        formatter: "<b>{a}</b> <br/>{b}: {c} ({d}%)"
                    },
                    series: [
                        {
                            name: 'City:',
                            type: 'pie',
                            selectedMode: 'single',
                            data: sehirGelen,
                        },
                    ]
                });
                this.pie3.setOption({
                    tooltip: {
                        trigger: 'item',
                        formatter: "<b>{a}</b> <br/>{b}: {c} ({d}%)"
                    },
                    series: [
                        {
                            name: 'Operation System:',
                            type: 'pie',
                            selectedMode: 'single',
                            data: isletimGelen,
                        },
                    ]
                });
                this.pie4.setOption({
                    tooltip: {
                        trigger: 'item',
                        formatter: "<b>{a}</b> <br/>{b}: {c} ({d}%)"
                    },
                    series: [
                        {
                            name: 'Browser:',
                            type: 'pie',
                            selectedMode: 'single',
                            data: browserGelen,
                        },
                    ]
                })
            },
            destroyChart1() {
                if (this.chart1) {
                    this.chart1.dispose();
                    this.chart1 = null
                }
            },
            resizeChart1() {
                if (this.chart1) {
                    this.chart1.resize()
                }
            },
            resizePie() {
                if (this.pie) {
                    this.pie.resize()
                }
                if (this.pie2) {
                    this.pie2.resize()
                }
                if (this.pie3) {
                    this.pie3.resize()
                }
                if (this.pie4) {
                    this.pie4.resize()
                }
            },
            getAnalitics() {
                var ziyaretciData = [];
                var sessionData = [];
                var ulkeData = [];
                var sehirData = [];
                var isletimData = [];
                var browserData = [];
                var toplam = [];
                // var renkler = ["#2C73D2","#00C9A7","#534E55","#933F49","#B0A8B9","#296073","#4B4453","#4D8076","#EBAF40","#072E3D"]
                analitikService.analitikList().then((response) => {
                    if(response.status == 200){
                        localStorage.setItem("userDataDemirag", response.token);
                    }
                    response.ziyaretci[0].rows.forEach(function (e) {
                        for (var j = 1; j < 3; j++) {
                            if (j === 1) {
                                ziyaretciData.push(parseInt(e[j]))
                            } else {
                                sessionData.push(parseInt(e[j]))
                            }
                        }
                    });
                    for (var i = 0; i < ziyaretciData.length; i++) {
                        toplam.push((parseInt(ziyaretciData[i]) + parseInt(sessionData[i])))
                    }

                    response.country[0].rows.forEach(function (e, d) {
                        if (d === 0) {
                            var item = {
                                value: parseInt(e[1]),
                                name: e[0],
                                selected: true,
                                // itemStyle: {normal: {color: renkler[d],}}
                            }
                        } else {
                            item = {
                                value: parseInt(e[1]),
                                name: e[0],
                                // itemStyle: {normal: {color: renkler[d],}}
                            }
                        }
                        ulkeData.push(item)
                    });

                    response.countrycc[0].rows.forEach(function (e, d) {
                        if (d === 0) {
                            var item2 = {
                                value: parseInt(e[1]),
                                name: e[0],
                                selected: true,
                                // itemStyle: {normal: {color: renkler[d],}}
                            }
                        } else {
                            item2 = {
                                value: parseInt(e[1]),
                                name: e[0],
                                // itemStyle: {normal: {color: renkler[d],}}
                            }
                        }
                        sehirData.push(item2)
                    });

                    response.operatingSystem[0].rows.forEach(function (e, d) {
                        if (d === 0) {
                            var item3 = {
                                value: parseInt(e[1]),
                                name: e[0],
                                selected: true,
                                // itemStyle: {normal: {color: renkler[d],}}
                            }
                        } else {
                            item3 = {
                                value: parseInt(e[1]),
                                name: e[0],
                                // itemStyle: {normal: {color: renkler[d],}}
                            }
                        }
                        isletimData.push(item3)
                    });

                    response.browser[0].rows.forEach(function (e, d) {
                        if (d === 0) {
                            var item4 = {
                                value: parseInt(e[1]),
                                name: e[0],
                                selected: true,
                                // itemStyle: {normal: {color: renkler[d],}}
                            }
                        } else {
                            item4 = {
                                value: parseInt(e[1]),
                                name: e[0],
                                // itemStyle: {normal: {color: renkler[d],}}
                            }
                        }
                        browserData.push(item4)
                    });

                    this.initChart1(ziyaretciData, sessionData, toplam);
                    this.initPie(ulkeData, sehirData, isletimData, browserData);
                }).catch(err => {
                    if(err.responseJSON){
                        let error = err.responseJSON
                        if(error.status == 401){
                            notification.Status(150, this);
                        }
                        else notification.Status("errorMsg", this, error.errMsg)
                    }else{
                        //notification.Status(503, this)
                    }
                })
            },

            getDashboardGorunur() {
                this.getAnalitics();
                this.ustSayi = 0;
                this.ustSayi2 = 0;
                this.altSayi = 0;
                let self = this;
                self.$store.state.layout.dashboardGorunum.forEach(function (e) {
                    //['sayfaSayisi', 'siparisSayisi', 'urunSayisi', 'musteriSayisi', 'markaSayisi', 'kargoSayisi', 'ziyaretciIstatistik', 'islemListesi', 'yoneticiListesi'],
                    switch (e) {
                        case "sayfaSayisi":
                            self.sayfaCountGorunum = true;
                            self.ustSayi++;
                            self.getSayfaCount();
                            self.listKey++;
                            break;
                        case "siparisSayisi":
                            self.siparisCountGorunum = true;
                            self.ustSayi2++;
                            self.getSiparisCount();
                            self.listKey++;
                            break;
                        case "urunSayisi":
                            self.urunCountGorunum = true;
                            self.ustSayi++;
                            self.getUrunCount();
                            self.listKey++;
                            break;
                        case "musteriSayisi":
                            self.musteriCountGorunum = true;
                            self.ustSayi++;
                            self.getMusteriCount();
                            self.listKey++;
                            break;
                        case "markaSayisi":
                            self.markaCountGorunum = true;
                            self.ustSayi++;
                            self.getMarkaCount();
                            self.listKey++;
                            break;
                        case "ziyaretciIstatistik":
                            self.istatistikGorunum = true;
                            self.analiticsKey++;
                            break;
                        case "islemListesi":
                            self.islemGorunum = true;
                            self.altSayi++;
                            self.getIslemList();
                            break;
                        case "yoneticiListesi":
                            self.yoneticiGorunum = true;
                            self.altSayi++;
                            self.getYonetimList();
                            break;
                        
                    }
                });
            },
            route(name, durum) {
                var tabList = this.$store.state.tabList;
                var header = functions.linkTemizle(name.replace(" ", ""));
                this.$store.commit('changeSiparisDurum', durum);
                var obj = tabList.find(data => data.title === name);
                if (obj === undefined) {
                    tabList.push({
                        title: name,
                        name: header,
                        contentName: header
                    });
                    this.$store.commit('changeTabList', tabList);
                    this.$store.commit('changeActiveTabs', header);
                } else {
                    this.$store.commit('changeActiveTabs', header);

                }
            },
            getYonetimList() {
                yoneticiService.yoneticiListe(1, 0, 10).then(response => {
                    if(response.status == 200){
                        localStorage.setItem("userDataDemirag", response.token);
                        this.yonetimListe = response.data;
                    }
                }).catch(err => {
                    if(err.responseJSON){
                        let error = err.responseJSON
                        if(error.status == 401){
                            notification.Status(150, this);
                        }
                        else notification.Status("errorMsg", this, error.errMsg)
                    }else{
                        //notification.Status(503, this)
                    }
                    this.stateLoading = false;
                })
            },
            getIslemList() {
                islemService.getIslemList().then(response => {
                    if(response.status == 200){
                        localStorage.setItem("userDataDemirag", response.token);
                        response.data.data.forEach(el => {
                            el.aciklama = spliceText(el.islem).join(" ")
                        })
                        this.islemList = response.data.data;
                    }
                }).catch(err => {
                    if(err.responseJSON){
                        let error = err.responseJSON
                        if(error.status == 401){
                            notification.Status(150, this);
                        }
                        else notification.Status("errorMsg", this, error.errMsg)
                    }else{
                        //notification.Status(503, this)
                    }
                    this.stateLoading = false;
                })
            },
            getSayfaCount() {
                sayfaService.sayfaAktifPasifSayisi().then(response => {
                    if(response.status == 200){
                        localStorage.setItem("userDataDemirag", response.token);
                        this.sayilar.aktifSayfa = response.aktifSayfa
                        this.sayilar.pasifSayfa = response.pasifSayfa
                    }
                }).catch(err => {
                    if(err.responseJSON){
                        let error = err.responseJSON
                        if(error.status == 401){
                            notification.Status(150, this);
                        }
                        else notification.Status("errorMsg", this, error.errMsg)
                    }else{
                        //notification.Status(503, this)
                    }
                })
            },
            getMarkaCount(){
            },
            getSiparisCount(){
            },
            getUrunCount(){
            },
            getMusteriCount(){
            },
          
            indexMethod(index){
                return index + 1;
            },
            
        },

        created(){
             
        },

        mounted() {
            this.yetkiTuru = this.$store.getters.getYetkiTuru 
            this.getDosyaList();
            let self = this;

            ////'sayfaSayisi', 'siparisSayisi', 'urunSayisi', 'musteriSayisi', 'sayfaList', 'urunListesi', 'markaListesi', 'kargoListesi', 'ziyaretciIstatistik', 'islemListesi', 'yoneticiListesi'
            EventBus.$on('dashboardAyar', function (bool) {
                self.sayfaCountGorunum = false;
                self.siparisCountGorunum = false;
                self.urunCountGorunum = false;
                self.musteriCountGorunum = false;
                self.markaCountGorunum = false;
                self.kargoCountGorunum = false;
                self.istatistikGorunum = false;
                self.islemGorunum = false;
                self.yoneticiGorunum = false;
                if (bool) {
                    self.getDashboardGorunur()
                }
            });
            this.root = true
            this.getDashboardGorunur();
            
        },
    }

    function spliceText(text){
        return text.split(/(?=[A-Z])/);
    }

</script>

<style lang="scss" scoped>
    @import '../../assets/scss/_variables';

    .centeredText{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .account-tab {

        .card-outline {
            min-height: 130px;
            border: 1px solid $text-color-accent;

            .title {
                font-size: 16px;
                margin-bottom: 10px;
                font-weight: bold;
                color: white;
                background: $text-color-accent;
                display: inline-block;
                position: relative;
                top: -24px;
                margin-left: -20px;
                padding: 4px 10px;
                text-transform: uppercase;
            }
        }

        .widget-profile {
            .avatar {
                width: 70px;

                img {
                    width: 100%;
                }
            }

            .box.grow {
                height: 70px;
                padding: 0 20px;
                box-sizing: border-box;
                cursor: pointer;
                
                .since {
                    font-size: 12px;
                    opacity: .5;
                }

                &:hover {
                }
            }
        }

        .widget-order {
            .photo {
                width: 70px;

                img {
                    width: 100%;
                }
            }

            .box.grow {
                height: 70px;
                padding: 0 20px;
                box-sizing: border-box;
                cursor: pointer;

                .date {
                    font-size: 12px;
                    opacity: .5;
                }

                &:hover {
                }
            }
        }

        .widget-achievements {
            .box.grow {
                height: 70px;
                line-height: 70px;
                font-size: 30px;
                box-sizing: border-box;
                text-align: center;
            }
        }

        .widget-address {
            .box.grow {
                height: 70px;
                padding: 0px 20px;
                box-sizing: border-box;
                cursor: pointer;

                .street {
                    font-size: 12px;
                    opacity: .5;
                    margin-top: -5px;
                }

                &:hover {
                }
            }
        }
    }

    .widget {
        height: 250px;
        position: relative;

        .widget-header {

            .widget-icon-box {
                background: rgba(0, 0, 0, .02);
                border: 1px solid rgba(0, 0, 0, .02);
                border-radius: 4px;
                text-align: center;
                width: 80px;
                aspect-ratio: 1/1
            }

            .widget-title {
                cursor: pointer;
                font-weight: bold;
            }
        }

    }

    @media (min-width: 768px) and (max-width: 1040px) {
        .radio-switcher {
            display: none;
        }

        .widget {
            .widget-header {
                .widget-icon-box {
                    display: none;
                }
            }
        }
    }

    @media (max-width: 420px) {
        .radio-switcher {
            display: none;
        }
    }
</style>

<style lang="scss">
    @import '../../assets/scss/_variables';

    .bg-accent {
        background-color: #21c1d666 !important;
    }
    .el-divider__text {
        background-color: #f2f7fa;
    }

    .page-dashboard {

        .widget {
            .peity {
                position: absolute;
                bottom: -1px;
                left: 0;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }

        table.styled {
            .peity {
                margin-right: 10px;
            }
        }

        .vb-content {
            padding: 0 20px;
            box-sizing: border-box !important;
            margin-top: -5px;
            margin-left: -20px;
            margin-right: -20px;
            height: calc(100% + 15px) !important;
            width: calc(100% + 40px) !important;
        }
    }

    @media (max-width: 768px) {
        .page-dashboard {
            .vb-content {
                padding: 0 5px !important;
                margin: -5px;
                width: calc(100% + 10px) !important;
            }
        }
    }

    .account-tab .widget-address .box.grow .street {
        font-size: 12px;
        opacity: .5;
        margin-top: -5px;
    }

    

    .page-dashboard {
        .el-collapse-item {
            box-shadow: 0 8px 16px 0 rgba(40, 40, 90, .09), 0 3px 6px 0 rgba(0, 0, 0, .065);
            border-radius: 5px;

            .el-collapse-item__header {
                cursor: pointer !important;
                font-size: 14px !important;
                font-weight: 500;
                height: 40px;
                background-color: $dashboard-collapse-header !important;
                color:  $background-color !important;
                font-weight: 500;
            }

            .el-collapse-item__content {
                padding-bottom: 0;
            }

            .el-collapse-item__wrap {
                border-bottom: none;
            }
        }

        .widget-info {
            h2 {
                font-size: 20px !important;
                line-height: 1.4;
            }

            .widget-title {
                font-size: 14px !important;
                line-height: 1.4;
            }

            button {
                z-index: 99;
                position: relative;
            }
        }
    }

    .header-red{
        .el-collapse-item{
            .el-collapse-item__header{
                background-color:rgb(194, 17, 4) !important;
            }
        }
    }

    .delay-200ms {
        animation-delay: 200ms;
    }
</style>

<style>

    table.styled thead tr th {
        color: rgb(2, 88, 88);
        background-color: rgb(231, 241, 239);    
        line-height:15px;
    }

    .satir{
        font-size: 13px;
        line-height:15px;
    }


    .el-badge__content {
        background-color: red !important;
        color: white;
        width: 25px;
        height: 25px;
        padding: 0;
        border-radius: 50%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 16px;
        font-weight: 450;
        margin-bottom: -20px !important;        
        margin-left: -10px
    }

    .anasayfa .el-badge__content.is-fixed {
        position: absolute;
        top: 0;
    }

    .el-loading-spinner {
        color: #048484;
        margin: 60px 0;
        font-size: 14px;
    }

</style>

