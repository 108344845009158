<template>
    <div>
        <!-- && !menuSecims -->
        <div v-if="(yetkiTuru === '1' || yetkiTuru === '2' || yetkiTuru === '3') " class="web">
            <el-menu
                :default-active.sync="activeLink"
                :mode="mode"
                @select="goto"
                :collapse="isCollapse"
                :unique-opened="true"
                background-color="transparent"
                class="main-navigation-menu"
                :class="{'nav-collapsed':isCollapse}">

                <el-submenu v-if="(yetkiTuru === '1')" index="firma" popper-class="main-navigation-submenu">
                    <template slot="title">
                        <i class="mdi mdi-city"></i><span>Firma</span>
                    </template>
                    <el-menu-item index="/firma/detay">
                        <span slot="title">Firma Detay</span>
                    </el-menu-item>
                    <el-submenu index="2-4">
                        <template slot="title">Yönetici İşlemleri</template>
                        <el-menu-item>
                            <span slot="title">Yönetici Tanımla</span>
                        </el-menu-item>
                        <el-menu-item>
                            <span slot="title">Yönetici Listesi</span>
                        </el-menu-item>
                    </el-submenu>
                </el-submenu>
                
                
                <el-submenu v-if="(yetkiTuru === '1' || yetkiTuru === '2')" index="cari" popper-class="main-navigation-submenu">
                    <template slot="title">
                        <i class="mdi mdi-office-building"></i><span>Cari</span>
                    </template>
                    <el-menu-item index="/cari/tanimla">
                        <span slot="title">Cari Tanımla</span>
                    </el-menu-item>
                    <el-menu-item index="/cari/liste">
                        <span slot="title">Cari Listesi</span>
                    </el-menu-item>
                </el-submenu>

                
                <el-menu-item index="/cari/liste" v-if="yetkiTuru === '3'" popper-class="main-navigation-submenu">                     
                    <i class="mdi mdi-office-building"> </i><span slot="title">Cari Listesi</span>
                </el-menu-item>

                <el-submenu v-if="(yetkiTuru === '1' || yetkiTuru === '2')" index="evrak" popper-class="main-navigation-submenu">
                    <template slot="title">
                        <i class="mdi mdi-file-document"></i><span>Evrak</span>
                    </template>
                    <el-menu-item index="/evrak/tanimla">
                        <span slot="title">Evrak Tanımla</span>
                    </el-menu-item>
                    <el-menu-item index="/evrak/liste">
                        <span slot="title">Evrak Listesi</span>
                    </el-menu-item>
                </el-submenu>

                <el-menu-item index="/evrak/liste" v-if="yetkiTuru === '3'" popper-class="main-navigation-submenu">                     
                    <i class="mdi mdi-file-document"> </i><span slot="title">Evrak Listesi</span>
                </el-menu-item>
                 
            </el-menu>
        </div>

        <!-- <div v-if="(yetkiTuru === '1') && menuSecim" class="otomasyon">
            <el-menu
            :default-active.sync="activeLink"
            :mode="mode"
            @select="goto"
            :collapse="isCollapse"
            :unique-opened="true"
            background-color="transparent"
            class="main-navigation-menu "
            :class="{'nav-collapsed':isCollapse}">

            </el-menu>
        </div> -->

    </div>
</template>

<script>
    import {detect} from 'detect-browser'
    import JQuery from 'jquery';

    let $ = JQuery;
    const browser = detect()

    export default {
        name: 'Nav',
        props: ['mode', 'isCollapse'],
        computed: {
            menuSecim(val){
                return this.$store.getters.getMenuSecim;
            },
        },
        watch: {
            menuSecim(val){
                this.$store.commit("changeRefreshKey")
            }
        },
        data() {
            return {
                yetkiTuru: "",
                isIe: true,
                isEdge: true,
                activeLink: null,
                tabList: [],
                yetkiList: {
                    yoneticiTanimlama: false,
                    yoneticiListesi: false,
                    sayfaTanimlama: false,
                    haberTanimlama: false,
                    haberKategoriTanimlama: false,
                    sayfaListesi: false,
                    haberListesi: false,
                    haberKategoriListesi: false,
                    popupTanimlama: false,
                    popuplistesi: false,
                    sliderTanimlama: false,
                    sliderListesi: false,
                    iletisim: false,
                    urunTanimlama: false,
                    urunListeleme: false,
                    urunKategoriTanimlamaYet: false,
                    urunOpsiyonTanimlamaYet: false,
                }
            }
        },
        methods: {
            goto(index, indexPath, id) {
                let self = this;
                this.tabList = this.$store.getters.getTabList;
                /*if (this.$store.state.tabList.length === 0) {
                    this.tabList = [];
                } */
                var obj = this.tabList.find(data => data.title === id.$el.textContent);
                var name = linkTemizle(id.$el.textContent.replace(" ", ""));
                if (obj === undefined) {
                    this.tabList.push({
                        title: id.$el.textContent,
                        name: name,
                        contentName: name
                    });
                    this.$store.commit('changeTabList', this.tabList);
                    this.$store.commit('changeActiveTabs', name);
                } else {
                    this.$store.commit('changeActiveTabs', name);
                }
                this.$emit('push-page', {page: index});
                $(".horizontal-nav.pos-top").addClass("shadowBox");

            },
            closeSidebar() {
                this.openSidebar = false
            },
            setLink(path) {
                this.activeLink = path
            }
        },
        created() {
            if (browser.name !== 'ie') this.isIe = false
            if (browser.name !== 'edge') this.isEdge = false
            this.setLink(this.$router.currentRoute.path)
            this.$router.afterEach((to, from) => {
                this.setLink(this.$router.currentRoute.path)
            })
            if (localStorage.getItem("userDataDemirag")) {
                this.yetkiTuru = this.$store.getters.getYetkiTuru;
                console.log(this.yetkiTuru)
            }
        },
    }


    function linkTemizle(text) {
        var trMap = {
            'çÇ': 'c',
            'ğĞ': 'g',
            'şŞ': 's',
            'üÜ': 'u',
            'ıİ': 'i',
            'öÖ': 'o'
        };
        for (var key in trMap) {
            text = text.replace(new RegExp('[' + key + ']', 'g'), trMap[key]);
        }
        return text.replace(/[^-a-zA-Z0-9\s]+/ig, '')
            .replace(/\s/gi, "-")
            .replace(/[-]+/gi, "-").toLowerCase();

    }
</script>

<style lang="scss">
    @import '../assets/scss/_variables';

    .main-navigation-menu {
        transition: width .5s;

        &:not(.el-menu--collapse) {
            .el-submenu__title, .el-menu-item {
                height: 40px;
                line-height: 40px;
                background-color: transparent !important;
            }

            &:not(.el-menu--horizontal) {
                .el-menu-item, .el-submenu {
                    position: relative;

                    &::before {
                        content: '';
                        display: block;
                        width: 0px;
                        height: 1px;
                        position: absolute;
                        bottom: 10px;
                        left: 30px;
                        background: $text-color;
                        z-index: 1;
                        opacity: 0;
                        transition: all .7s cubic-bezier(.55, 0, .1, 1);
                    }

                    &:hover {
                        &::before {
                            width: 100px;
                            opacity: 1;
                            //left: 50px;
                            transform: translate(20px, 0);
                        }
                    }

                    &.is-active {
                        &::before {
                            background: $text-color-accent;
                        }
                    }
                }
            }

            .el-submenu.is-opened {
                //background: #edf1f6 !important;
                //background: rgba(223, 228, 234, 0.38) !important;
                position: relative;

                &::after {
                    content: '';
                    display: block;
                    width: 2px;
                    position: absolute;
                    top: 40px;
                    bottom: 10px;
                    left: 31px;
                    background: $text-color;
                    z-index: 1;
                }

                &::before {
                    display: none;
                }

                .el-menu-item, .el-submenu {
                    &::before, &::after {
                        display: none;
                    }
                }
            }

            .el-menu-item-group__title {
                padding: 15px 0 0px 20px;
                color: transparentize($text-color, 0.65);
            }
        }

        .el-submenu__title, .el-menu-item:not(.is-active) {
            color: $text-color;

            i {
                color: $text-color;
            }
        }

        &.el-menu--collapse {
            .el-menu-item-group__title {
                padding: 15px 0 0px 0px;
                width: 100%;
                text-align: center;
            }

            .el-submenu__title:hover, .el-menu-item:hover {
                background-color: rgba(0, 0, 0, 0.05) !important;
            }
        }

        &.el-menu--horizontal {
            white-space: nowrap;
            /*width: fit-content;
            width: max-content;*/
            overflow: hidden;
            display: table;

            & > * {
                float: inherit !important;
                display: inline-block;
            }
        }

        &.nav-collapsed {
            .el-menu-item, .el-submenu__title {
                & > span {
                    display: none;
                }
            }
        }
    }

    .main-navigation-submenu {
        .el-menu {
            background: #fff !important;

            .el-menu-item:not(.is-active) {
                color: $text-color;
            }

            .el-menu-item:hover {
                background-color: transparentize($background-color, 0.3) !important;
            }
        }
    }

    .main-navigation-menu:not(.el-menu--collapse):not(.el-menu--horizontal) .el-submenu {
        position: relative;
        border: none !important;
    }

    .main-navigation-menu:not(.el-menu--collapse):not(.el-menu--horizontal) .el-menu-item, .main-navigation-menu:not(.el-menu--collapse):not(.el-menu--horizontal) .el-submenu {
        position: relative;
        border: none !important;
    }

    @media (min-width: 768px) {
        @import '../assets/scss/_variables';
        .main-navigation-menu {
            transition: width .5s;

            &:not(.el-menu--collapse) {
                .el-submenu__title, .el-menu-item {
                    height: 69px;
                    line-height: 20px;
                    text-align: -webkit-center;
                    background-color: transparent !important;
                }
            }

            &.el-menu--horizontal {
                white-space: nowrap;
                overflow: hidden;
                display: table;

                & > * {
                    float: inherit !important;
                    display: inline-block;
                    border-right: 1px solid #dadfe3;
                }
            }
        }
        .el-menu--horizontal > .el-submenu .el-submenu__icon-arrow {
            display: table-cell;
            position: relative;
            right: 0;
            top: -3px;
            vertical-align: middle;
        }
        .el-submenu__title span {
            position: relative !important;
            bottom: 2px !important;
        }
        .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
            position: relative;
            top: 2px;
        }
        .main-navigation-submenu {
            .el-menu {
                background: #e3eaf0 !important;

                .el-menu-item:not(.is-active) {
                    color: $text-color;
                }

                .el-menu-item:hover {
                    background-color: transparentize($background-color, 0.3) !important;
                }
            }
        }
    }

</style>
<style lang="scss" scoped>
    @import '../assets/scss/_variables';

    .el-menu {
        border: none;
    }

    .el-menu::before, .el-menu::after {
        display: none;
    }


    .el-submenu, .el-menu-item {
        .mdi {
            vertical-align: middle;
            margin-right: 0;
            display: inline-block;
            width: 24px;
            text-align: center;
            font-size: 18px;
        }
    }

    @media (min-width: 768px) {
        .el-submenu, .el-menu-item {
            .mdi {
                vertical-align: middle;
                position: relative;
                top: 5px;
                display: flex;
                width: 24px;
                text-align: center;
                font-size: 17px;
            }
        }
    }

</style>

<style scoped>


    .main-navigation-menu.el-menu--horizontal > li {
        position: relative;
    }

    .main-navigation-menu.el-menu--horizontal > li:before {
        position: absolute;
        display: block !important;
        content: "";
        top: -5px;
        left: 0;
        height: 8px;
        width: 100%;
        z-index: 99;
    }
</style>

<style lang="scss" scoped>
// OTOMASYON
    .otomasyon{
        .main-navigation-menu.el-menu--horizontal > li:nth-child(1):before {
            background: #048484;
        }

        .main-navigation-menu.el-menu--horizontal > li:nth-child(2):before {
            background: #f7be06;
        }

        .main-navigation-menu.el-menu--horizontal > li:nth-child(3):before {
            background: #f1ba04;
        }

        .main-navigation-menu.el-menu--horizontal > li:nth-child(4):before {
            background: #ecb604;
        }

        .main-navigation-menu.el-menu--horizontal > li:nth-child(5):before {
            background: #e7b203;
        }

        .main-navigation-menu.el-menu--horizontal > li:nth-child(6):before {
            background: #e0ad04;
        }

        .main-navigation-menu.el-menu--horizontal > li:nth-child(7):before {
            background: #ddab05;
        }

        .main-navigation-menu.el-menu--horizontal > li:nth-child(8):before {
            background: #d8a705;
        }

        .main-navigation-menu.el-menu--horizontal > li:nth-child(9):before {
            background: #d1a206;
        }
        .main-navigation-menu.el-menu--horizontal > li:nth-child(10):before {
            background: #cc9e06;
        }

        .main-navigation-menu.el-menu--horizontal > li:nth-child(11):before {
            background: #c99b05;
        }

        .main-navigation-menu.el-menu--horizontal > li:nth-child(12):before {
            background: #c09403;
        }

        .main-navigation-menu.el-menu--horizontal > li:nth-child(13):before {
            background: #b98f03;
        }

        .main-navigation-menu.el-menu--horizontal > li:nth-child(14):before {
            background: #b68c03;
        }
    }

    /* WEB  */
    .web{
        .main-navigation-menu.el-menu--horizontal > li:nth-child(1):before {
            background: #268d8d;
        }

        .main-navigation-menu.el-menu--horizontal > li:nth-child(2):before {
            background: #258d8d;
        }

        .main-navigation-menu.el-menu--horizontal > li:nth-child(3):before {
            background: #238d8d;
        }
        .main-navigation-menu.el-menu--horizontal > li:nth-child(4):before {
            background: #228f8f;
        }
        .main-navigation-menu.el-menu--horizontal > li:nth-child(5):before {
            background: #208f8f;
        }
        .main-navigation-menu.el-menu--horizontal > li:nth-child(6):before {
            background: #209191;
        }
        .main-navigation-menu.el-menu--horizontal > li:nth-child(7):before {
            background: #1e8f8f;
        }
        .main-navigation-menu.el-menu--horizontal > li:nth-child(8):before {
            background: #1d8d8d;
        }
        .main-navigation-menu.el-menu--horizontal > li:nth-child(9):before {
            background: #1b8b8b;
        }
        .main-navigation-menu.el-menu--horizontal > li:nth-child(10):before {
            background: #198b8b;
        }
        .main-navigation-menu.el-menu--horizontal > li:nth-child(11):before {
            background: #188b8b;
        }
        .main-navigation-menu.el-menu--horizontal > li:nth-child(12):before {
            background: #178b8b;
        }
        .main-navigation-menu.el-menu--horizontal > li:nth-child(13):before {
            background: #158888;
        }
        .main-navigation-menu.el-menu--horizontal > li:nth-child(14):before {
            background: #148888;
        }
        .main-navigation-menu.el-menu--horizontal > li:nth-child(15):before {
            background: #118888;
        }
    }

</style>