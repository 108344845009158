import WsProvider from './provider/WSProvider'

var serviceName = "webservis";
var moduleName = "dosya";

var dosyaService = {
    imagePath: WsProvider.imagePath,
    imagePathBuyuk: WsProvider.imagePathBuyuk,
    imagePathOrta: WsProvider.imagePathOrta,
    imagePathKucuk: WsProvider.imagePathKucuk,
    dosyaPath: WsProvider.documentPath,
    path: WsProvider.path,

    dosyaListele(baslangic, limit, dosyaDurumu) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            baslangic,
            limit,
            dosyaDurumu,
            serviceName: moduleName,
            methodName: "dosyaListele"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    tumDosyaListele() {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            serviceName: moduleName,
            methodName: "tumDosyaListele"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },


    kurumListele(durum, baslangic, limit){
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            durum,
            baslangic, 
            limit,
            serviceName: moduleName,
            methodName: "kurumListele"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    evrakDurum(){
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            serviceName: moduleName,
            methodName: "evrakDurum"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    dosyaDurumDegis(dosyaID, durum) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            dosyaID,
            durum,
            serviceName: moduleName,
            methodName: "dosyaAktifPasifYap"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    tarihKontrol(tarih) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            tarih,
            serviceName: moduleName,
            methodName: "tarihKontrol"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    bosDosyaEkle(dosyaDurumu, evrak, evrakKonu, kurum, islemTarihi) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            dosyaDurumu,
            evrak,
            evrakKonu,
            kurum,
            islemTarihi,
            serviceName: moduleName,
            methodName: "bosDosyaEkle"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    dosyaEkle(kurumID, dosyaDurumu, evrakBaslik, islemTarihi, belge) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            kurumID,
            dosyaDurumu,
            evrakBaslik,
            islemTarihi,
            belge,
            serviceName: moduleName,
            methodName: "dosyaEkle"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    dosyaGuncelle(dosyaID, kurumID, konu, belge) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            dosyaID,
            kurumID,
            konu,
            belge,
            serviceName: moduleName,
            methodName: "dosyaGuncelle"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    dosyaGuncelle2(dosyaID, konu) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            dosyaID,
            konu,
            serviceName: moduleName,
            methodName: "dosyaGuncelle"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    dosyaDetay(dosyaID) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            dosyaID,
            serviceName: moduleName,
            methodName: "dosyaDetay"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    dosyaSil(dosyaID) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            dosyaID,
            serviceName: moduleName,
            methodName: "dosyaSil"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    dosyaArama(kurumAdi, konu, kayitKodu, dosyaDurumu, islemTarihi, baslangic, limit) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            kurumAdi,
            konu,
            kayitKodu,
            dosyaDurumu,
            islemTarihi,
            baslangic,
            limit,
            serviceName: moduleName,
            methodName: "dosyaArama"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

}

export default dosyaService