import WsProvider from './provider/WSProvider'

const serviceName = "webservis";
const moduleName = "islem";

var islemService = {
    imagePath: WsProvider.imagePath,
    imagePathBuyuk: WsProvider.imagePathBuyuk,
    imagePathOrta: WsProvider.imagePathOrta,
    imagePathKucuk: WsProvider.imagePathKucuk,
    belgePath: WsProvider.documentPath,
    path: WsProvider.path,

    getIslemList() {
        var userDataDemirag = localStorage.getItem("userDataDemirag");
        var param = {
            token: userDataDemirag,
            serviceName: 'firma',
            methodName: "islemListesi"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },

}

export default islemService