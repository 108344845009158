const Hizmet = resolve => {
    require.ensure(['../views/apps/hizmet/Hizmet'], () => {
        resolve(require('../views/apps/hizmet/Hizmet'))
    })
};
const HizmetTanimla = resolve => {
    require.ensure(['../views/apps/hizmet/HizmetTanimla'], () => { 
        resolve(require('../views/apps/hizmet/HizmetTanimla'))
    })
};
const HizmetListe = resolve => {
    require.ensure(['../views/apps/hizmet/HizmetListe'], () => {
        resolve(require('../views/apps/hizmet/HizmetListe'))
    })
};
const HizmetDuzenle = resolve => {
    require.ensure(['../views/apps/hizmet/HizmetDuzenle'], () => {
        resolve(require('../views/apps/hizmet/HizmetDuzenle'))
    })
};

import Vue from 'vue'
import layouts from '../layout'
Vue.component('hizmettanimla', HizmetTanimla);
Vue.component('hizmetlistesi', HizmetListe);
Vue.component('hizmetguncelle', HizmetDuzenle);

export default {
    path: '/hizmet',
    name: 'hizmet',
    component: Hizmet,
    meta: {
        auth: true,
        layout: layouts.navLeft
    },
}